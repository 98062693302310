import { Badge, BadgeType, Element, UtilColor } from 'bambooo';
import { ManufacturerType } from 'kavula_schemas';
import { Lang } from '../Lang/Lang';
/**
 * ManufacturerTypeDisplay
 */
export class ManufacturerTypeDisplay extends Element {
    /**
     * Constructor
     * @param {Element|any} element
     * @param {ManufacturerType|number} type
     */
    constructor(element, type) {
        super();
        let name = '';
        let color = '';
        switch (`${type}`) {
            case ManufacturerType.automobile:
                name = Lang.i().l('Automobile');
                color = '#28a745';
                break;
            case ManufacturerType.taximeter:
                name = Lang.i().l('Taximeter');
                color = '#ffc107';
                break;
            case ManufacturerType.none:
            default:
                name = Lang.i().l('None');
                color = '#e9ecef';
        }
        this._badge = new Badge(element, `<b style="color: ${UtilColor.getContrastYIQ(color)}">${name}</b>`, BadgeType.info, color);
        this._element = this._badge.getElement();
    }
}
