import { Vts } from 'vts';
import { BasePage } from './BasePage';
import { Dashboard } from './Dashboard';
/**
 * Error page for user
 */
export class Error extends BasePage {
    /**
     * constructor
     */
    constructor(status, msg) {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'error';
        this._status = 0;
        this._msg = '';
        this._status = status;
        this._msg = msg;
        this.setTitle(`${status} Error Page`);
    }
    /**
     * loadContent
     */
    async loadContent() {
        let textType = 'text-warning';
        if (this._status >= 500) {
            textType = 'text-danger';
        }
        const content = this._wrapper.getContentWrapper().getContent();
        const divError = jQuery('<div class="error-page"></div>').appendTo(content.getElement());
        const h2Title = jQuery(`<h2 class="headline ${textType}"></h2>`).appendTo(divError);
        const divErrorContent = jQuery('<div class="error-content"></div>').appendTo(divError);
        jQuery('<h3>' +
            `<i class="fas fa-exclamation-triangle ${textType}"></i>` +
            ' Oops! Something went wrong.' +
            '</h3>').appendTo(divErrorContent);
        h2Title.append(`${this._status}`);
        divErrorContent.append(this._msg);
        divErrorContent.append('<br>Meanwhile, you may return to ');
        const backDashboard = jQuery('<a href="#">dashboard</a>').appendTo(divErrorContent);
        backDashboard.on('click', async (event) => {
            if (!Vts.isNull(this._pageLoader)) {
                await this._pageLoader.load(new Dashboard());
            }
            event.preventDefault();
        });
    }
}
