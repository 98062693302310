import { SchemaDefaultReturn, SchemaSettingEMailServerResponse, SchemaSettingManufacturerResponse, SchemaSettingTaximeterResponse, SchemaSettingVehicleTypes, SchemaSettingWooCommerceResponse } from 'kavula_schemas';
import { Vts } from 'vts';
import { UnknownResponse } from '../Net/Error/UnknownResponse';
import { NetFetch } from '../Net/NetFetch';
/**
 * Setting API
 */
export class SettingApi {
    /**
     * Return the Woocomerce settings
     * @returns {SettingWooCommerce}
     */
    static async getWoocommerceSettings() {
        const result = await NetFetch.postData('/json/v1/settings/woocomerce/get', {}, SchemaSettingWooCommerceResponse);
        if (Vts.isUndefined(result.data)) {
            throw new UnknownResponse('WooCommerce data is empty!');
        }
        return result.data;
    }
    /**
     * Save the Woocommerce settings
     * @param {SettingWooCommerce} settings
     */
    static async saveWoocommerceSettings(settings) {
        const request = {
            data: settings
        };
        await NetFetch.postData('/json/v1/settings/woocomerce/save', request, SchemaDefaultReturn);
        return true;
    }
    /**
     * Return the EMailServer settings
     * @returns {SettingEMailServer}
     */
    static async getEMailServerSettings() {
        const result = await NetFetch.postData('/json/v1/settings/emailserver/get', {}, SchemaSettingEMailServerResponse);
        if (Vts.isUndefined(result.data)) {
            throw new UnknownResponse('EMail-Server data is empty!');
        }
        return result.data;
    }
    /**
     * Save the EMailServer settings
     * @param {SettingEMailServer} settings
     * @returns {boolean}
     */
    static async saveEMailServerSettings(settings) {
        const request = {
            data: settings
        };
        await NetFetch.postData('/json/v1/settings/emailserver/save', request, SchemaDefaultReturn);
        return true;
    }
    /**
     * Return the Manufacturer list
     * @returns {Manufacturer[]}
     */
    static async getManufacturerList() {
        const result = await NetFetch.postData('/json/v1/settings/manufacturer/list', {}, SchemaSettingManufacturerResponse);
        if (Vts.isUndefined(result.data)) {
            throw new UnknownResponse('Manufacturer list is empty!');
        }
        return result.data;
    }
    /**
     * Save a Manufacturer
     * @param {Manufacturer} data
     * @returns {boolean}
     */
    static async saveManufacturer(data) {
        await NetFetch.postData('/json/v1/settings/manufacturer/save', data, SchemaDefaultReturn);
        return true;
    }
    /**
     * Return a Taximeter list
     * @returns {Taximeter[]}
     */
    static async getTaximeterList() {
        const result = await NetFetch.postData('/json/v1/settings/taximeter/list', {}, SchemaSettingTaximeterResponse);
        if (Vts.isUndefined(result.data)) {
            throw new UnknownResponse('Manufacturer list is empty!');
        }
        return result.data;
    }
    /**
     * Save a Taximeter
     * @param {Taximeter} data
     * @returns {boolean}
     */
    static async saveTaximeter(data) {
        await NetFetch.postData('/json/v1/settings/taximeter/save', data, SchemaDefaultReturn);
        return true;
    }
    /**
     * Return a VehicleTypes list
     * @returns {SettingVehicleTypeEntry[]}
     */
    static async getVehicleTypesList() {
        const result = await NetFetch.postData('/json/v1/settings/vehicletype/list', {}, SchemaSettingVehicleTypes);
        if (Vts.isUndefined(result.list)) {
            throw new UnknownResponse('VehicleTypes list is empty!');
        }
        return result.list;
    }
    static async saveVehicleType(data) {
        await NetFetch.postData('/json/v1/settings/vehicletype/save', data, SchemaDefaultReturn);
        return true;
    }
}
