import { SchemaDefaultReturn, SchemaUserInfoResponse, SchemaUserListResponse } from 'kavula_schemas';
import { Vts } from 'vts';
import { UnknownResponse } from '../Net/Error/UnknownResponse';
import { NetFetch } from '../Net/NetFetch';
/**
 * User
 */
export class UserApi {
    /**
     * getUserInfo
     */
    static async getUserInfo() {
        const result = await NetFetch.getData('/json/v1/user/info', SchemaUserInfoResponse);
        if (Vts.isUndefined(result.data)) {
            throw new UnknownResponse('Userinfo data is empty!');
        }
        return result.data;
    }
    /**
     * getUserList
     */
    static async getUserList() {
        const result = await NetFetch.postData('/json/v1/user/list', {}, SchemaUserListResponse);
        return result.list;
    }
    /**
     * saveUser
     * @param user
     */
    static async saveUser(user) {
        await NetFetch.postData('/json/v1/user/save', user, SchemaDefaultReturn);
        return true;
    }
    /**
     * save new password
     * @param password
     */
    static async saveNewPassword(password) {
        await NetFetch.postData('/json/v1/user/savepassword', password, SchemaDefaultReturn);
        return true;
    }
    /**
     * deleteUser
     * @param user
     */
    static async deleteUser(user) {
        await NetFetch.postData('/json/v1/user/delete', user, SchemaDefaultReturn);
        return true;
    }
}
