import { Card, CardBodyType, CardLine, CardType, CheckBox, ContentCol, ContentColSize, ContentRow, FormGroup, InputBottemBorderOnly2, InputType } from 'bambooo';
import { SettingApi } from '../../Api/SettingApi';
import { Lang } from '../../Lang/Lang';
import { ErrorDialog } from '../../Widget/ErrorDialog';
import { BasePage } from '../BasePage';
/**
 * EMailServer
 */
export class EMailServer extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'settings_emailserver';
        this.setTitle(`${Lang.i().l('settings')} - ${Lang.i().l('EMail-Server')}`);
    }
    /**
     * loadContent
     */
    async loadContent() {
        const row = new ContentRow(this._wrapper);
        const colForm = new ContentCol(row, ContentColSize.colMd9);
        this._onLoadTable = async () => {
            colForm.empty();
            // form
            const detailsCard = new Card(colForm, CardBodyType.none, CardType.secondary, CardLine.outline);
            detailsCard.setTitle('EMail-Server');
            // Server IP -----------------------------------------------------------------------------------------------
            const groupEMailIp = new FormGroup(detailsCard, 'IP/Address');
            const emailIpInput = new InputBottemBorderOnly2(groupEMailIp);
            emailIpInput.setValue('');
            // Username ------------------------------------------------------------------------------------------------
            const groupUsername = new FormGroup(detailsCard, 'Username');
            const usernameInput = new InputBottemBorderOnly2(groupUsername, undefined, InputType.text);
            usernameInput.show();
            // password ------------------------------------------------------------------------------------------------
            const groupPassword = new FormGroup(detailsCard, 'Password');
            const passwordInput = new InputBottemBorderOnly2(groupPassword, undefined, InputType.password);
            passwordInput.show();
            // port ----------------------------------------------------------------------------------------------------
            const groupPort = new FormGroup(detailsCard, 'SMTP Port');
            const portInput = new InputBottemBorderOnly2(groupPort, undefined, InputType.number);
            portInput.setPlaceholder('25, 587 or 465 (TLS)');
            // Use SSL -------------------------------------------------------------------------------------------------
            const groupSecure = new FormGroup(detailsCard, 'Secure');
            const secureCheck = new CheckBox(groupSecure, 'Secure enable');
            secureCheck.setChecked(false);
            const settings = await ErrorDialog.callTry(() => {
                return SettingApi.getEMailServerSettings();
            });
            if (settings) {
                emailIpInput.setValue(settings.ip);
                portInput.setValue(settings.port);
                usernameInput.setValue(settings.username);
                passwordInput.setValue(settings.password);
                secureCheck.setChecked(settings.secure === '1');
            }
            const btnSave = jQuery('<button type="button" class="btn btn-primary">Save Settings</button>').appendTo(detailsCard.getMainElement());
            btnSave.on('click', async () => {
                try {
                    if (await SettingApi.saveEMailServerSettings({
                        ip: emailIpInput.getValue(),
                        port: portInput.getValue(),
                        username: usernameInput.getValue(),
                        password: passwordInput.getValue(),
                        secure: secureCheck.isChecked() ? '1' : '0'
                    })) {
                        this._toast.fire({
                            icon: 'success',
                            title: 'EMailServer is save.'
                        });
                    }
                }
                catch (message) {
                    this._toast.fire({
                        icon: 'error',
                        title: message
                    });
                }
            });
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
