import { Card, CardBodyType, CardLine, CardType, ContentCol, ContentColSize, ContentRow, FormGroup, InputBottemBorderOnly2 } from 'bambooo';
import { SettingApi } from '../../Api/SettingApi';
import { Lang } from '../../Lang/Lang';
import { ErrorDialog } from '../../Widget/ErrorDialog';
import { BasePage } from '../BasePage';
/**
 * Woocommerce page
 */
export class Woocommerce extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'settings_woocommerce';
        this.setTitle(`${Lang.i().l('settings')} - ${Lang.i().l('WooCommerce')}`);
    }
    /**
     * loadContent
     */
    async loadContent() {
        const row = new ContentRow(this._wrapper);
        const colForm = new ContentCol(row, ContentColSize.colMd9);
        this._onLoadTable = async () => {
            colForm.empty();
            // form
            const detailsCard = new Card(colForm, CardBodyType.none, CardType.secondary, CardLine.outline);
            detailsCard.setTitle('WooCommerce');
            // URL -----------------------------------------------------------------------------------------------------
            const groupUrl = new FormGroup(detailsCard, 'URL');
            const urlInput = new InputBottemBorderOnly2(groupUrl);
            // Secret --------------------------------------------------------------------------------------------------
            const groupSecret = new FormGroup(detailsCard, 'Secret');
            const secretInput = new InputBottemBorderOnly2(groupSecret);
            // Key -----------------------------------------------------------------------------------------------------
            const groupKey = new FormGroup(detailsCard, 'Key');
            const keyInput = new InputBottemBorderOnly2(groupKey);
            // ---------------------------------------------------------------------------------------------------------
            const settings = await ErrorDialog.callTry(() => {
                return SettingApi.getWoocommerceSettings();
            });
            if (settings) {
                urlInput.setValue(settings.url);
                secretInput.setValue(settings.secret);
                keyInput.setValue(settings.key);
            }
            const btnSave = jQuery('<button type="button" class="btn btn-primary">Save Settings</button>').appendTo(detailsCard.getMainElement());
            btnSave.on('click', async () => {
                try {
                    if (await SettingApi.saveWoocommerceSettings({
                        url: urlInput.getValue(),
                        secret: secretInput.getValue(),
                        key: keyInput.getValue()
                    })) {
                        this._toast.fire({
                            icon: 'success',
                            title: 'WooCommerce is save.'
                        });
                    }
                }
                catch (message) {
                    this._toast.fire({
                        icon: 'error',
                        title: message
                    });
                }
            });
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
