/**
 * SchemaError
 */
export class SchemaError extends Error {
    /**
     * constructor
     * @param errors
     */
    constructor(errors) {
        super('Schema invalid from server response!');
        this._schemaErrors = errors;
    }
    /**
     * Return all errors to a json string
     * @returns {string}
     */
    errorsToStr() {
        return JSON.stringify(this._schemaErrors, null, 2);
    }
}
