import { HttpError } from './Error/HttpError';
import { UnknownResponse } from './Error/UnknownResponse';
import { Response } from './Response';
/**
 * NetFetch
 */
export class NetFetch {
    /**
     * postData
     * @param url
     * @param data
     * @param schema
     */
    static async postData(url, data, schema) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        let rdata;
        try {
            rdata = await response.json();
        }
        catch (e) {
            if (response.status === 200) {
                throw new UnknownResponse('Json prase error!');
            }
            else {
                throw new HttpError(response.statusText, response.status, url);
            }
        }
        Response.isResponse(schema, rdata);
        return rdata;
    }
    /**
     * getData
     * @param url
     * @param schema
     */
    static async getData(url, schema) {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        let data;
        try {
            data = await response.json();
        }
        catch (e) {
            if (response.status === 200) {
                throw new UnknownResponse('Json prase error!');
            }
            else {
                throw new HttpError(response.statusText, response.status, url);
            }
        }
        Response.isResponse(schema, data);
        return data;
    }
}
