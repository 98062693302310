import { Card, CardBodyType, FormGroup, FormRow, InputBottemBorderOnly2, LangText } from 'bambooo';
/**
 * Inquiry overview card
 */
export class InquiryOverviewCard extends Card {
    /**
     * Constructor
     * @param {any} elementObject
     */
    constructor(elementObject) {
        super(elementObject, CardBodyType.none);
        this.getHeaderElement().hide();
        const groupId = new FormGroup(this, new LangText('id'));
        this._id = new InputBottemBorderOnly2(groupId);
        this._id.setReadOnly(true);
        const rowST = new FormRow(this);
        const groupStatus = new FormGroup(rowST.createCol(6), new LangText('status'));
        this._status = new InputBottemBorderOnly2(groupStatus);
        this._status.setReadOnly(true);
        const groupTotal = new FormGroup(rowST.createCol(6), new LangText('total'));
        this._total = new InputBottemBorderOnly2(groupTotal);
        this._total.setReadOnly(true);
        const rowCM = new FormRow(this);
        const groupCreate = new FormGroup(rowCM.createCol(6), new LangText('create'));
        this._create = new InputBottemBorderOnly2(groupCreate);
        this._create.setReadOnly(true);
        const groupModified = new FormGroup(rowCM.createCol(6), new LangText('modified'));
        this._modified = new InputBottemBorderOnly2(groupModified);
        this._modified.setReadOnly(true);
    }
    /**
     * Set the data
     * @param {InquiryOverviewCardData} data
     */
    setData(data) {
        this._id.setValue(data.id);
        this._status.setValue(data.status);
        this._total.setValue(data.total);
        this._create.setValue(data.create);
        this._modified.setValue(data.modified);
    }
}
