import { SchemaDefaultReturn, SchemaIsLogin } from 'kavula_schemas';
import { NetFetch } from '../Net/NetFetch';
/**
 * Login
 */
export class LoginApi {
    /**
     * login
     * @param username
     * @param password
     */
    static async login(username, password) {
        await NetFetch.postData('/json/v1/login/login', {
            username,
            password
        }, SchemaDefaultReturn);
        return true;
    }
    /**
     * isLogin
     */
    static async isLogin() {
        const result = await NetFetch.getData('/json/v1/login/islogin', SchemaIsLogin);
        return result.status;
    }
    /**
     * logout
     */
    static async logout() {
        await NetFetch.getData('/json/v1/login/logout', SchemaDefaultReturn);
        return true;
    }
}
