import { ButtonMenu, ButtonType, Card, ColumnContent, ContentCol, ContentColSize, ContentRow, IconFa, LangText, Table, Td, Th, Tr } from 'bambooo';
import moment from 'moment/moment';
import { InquiryApi } from '../Api/InquiryApi';
import { Lang } from '../Lang/Lang';
import { ErrorDialog } from '../Widget/ErrorDialog';
import { BasePage } from './BasePage';
import { InquiryEditModal } from './Inquiry/InquiryEditModal';
/**
 * Inquiry
 */
export class Inquiry extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'inquiry';
        this._inquiryDialog = new InquiryEditModal(this._wrapper.getContentWrapper().getContent());
        this.setTitle(new LangText('inquiry'));
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            const row1 = new ContentRow(this._wrapper.getContentWrapper().getContent());
            const card = new Card(new ContentCol(row1, ContentColSize.col12));
            card.setTitle(new LangText('inquiry'));
            card.showLoading();
            const inquiries = await ErrorDialog.callTry(() => {
                return InquiryApi.getInquiryList();
            });
            const table = new Table(card.getElement());
            const trhead = new Tr(table.getThead());
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('ID')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Position')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Status')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Total')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Create')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Modified')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Action')
            ]));
            if (inquiries) {
                for (const inquiry of inquiries) {
                    const trbody = new Tr(table.getTbody());
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${inquiry.wc_order_id}`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${inquiry.wc_line_item0}`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${inquiry.wc_status}`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${inquiry.wc_total} ${inquiry.wc_currency}`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${moment(inquiry.wc_date_created).format('YYYY.MM.DD hh:mm')}`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${moment(inquiry.wc_date_modified).format('YYYY.MM.DD hh:mm')}`);
                    // action
                    const actionTd = new Td(trbody, '');
                    const btnMenu = new ButtonMenu(actionTd, IconFa.bars, true, ButtonType.borderless);
                    btnMenu.addMenuItem('Edit', () => {
                        this._inquiryDialog.show();
                        this._inquiryDialog.setOverviewData({
                            id: inquiry.wc_order_id,
                            status: inquiry.wc_status,
                            total: `${inquiry.wc_total} ${inquiry.wc_currency}`,
                            create: `${moment(inquiry.wc_date_created).format('YYYY.MM.DD hh:mm')}`,
                            modified: `${moment(inquiry.wc_date_modified).format('YYYY.MM.DD hh:mm')}`
                        });
                        this._inquiryDialog.setBilling(inquiry.wc_billing);
                        this._inquiryDialog.setShipping(inquiry.wc_shipping);
                    }, IconFa.edit);
                    btnMenu.addDivider();
                }
            }
            card.hideLoading();
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
