import { FormGroup, InputBottemBorderOnly2, InputType, LangText, ModalDialog, ModalDialogType } from 'bambooo';
import { Lang } from '../../Lang/Lang';
/**
 * Category edit modal
 */
export class CategoryEditModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'categorymodaldialog', ModalDialogType.large);
        /**
         * Unid from contact
         * @protected
         */
        this._unid = '';
        /**
         * on save click
         * @protected
         */
        this._onSaveClick = null;
        const groupName = new FormGroup(this._body, new LangText('name'));
        this._inputName = new InputBottemBorderOnly2(groupName);
        const groupColor = new FormGroup(this._body, new LangText('color'));
        this._inputColor = new InputBottemBorderOnly2(groupColor, 'catColor', InputType.colorpicker);
        // -------------------------------------------------------------------------------------------------------------
        jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
    }
    /**
     * Set the unid from a contact
     * @param {string} unid
     */
    setUnid(unid) {
        this._unid = unid;
    }
    /**
     * Return the unid from a contact
     * @returns {string}
     */
    getUnid() {
        return this._unid;
    }
    /**
     * set the name of category
     * @param {string} name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * get the name of category
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * set the color of category
     * @param {string} color
     */
    setColor(color) {
        this._inputColor.setValue(color === '' ? '#000000' : color);
    }
    /**
     * get the color of category
     */
    getColor() {
        return this._inputColor.getValue();
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setUnid('');
        this.setName('');
        this.setColor('#000000');
    }
    /**
     * set the on save event
     * @param {CategoryEditModalOnClickFn} onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
