import { ButtonMenu, ButtonType, Card, ColumnContent, ContentCol, ContentColSize, ContentRow, IconFa, LangText, LeftNavbarLink, Table, Td, Th, Tr } from 'bambooo';
import { SettingApi } from '../../Api/SettingApi';
import { Lang } from '../../Lang/Lang';
import { ErrorDialog } from '../../Widget/ErrorDialog';
import { ManufacturerTypeDisplay } from '../../Widget/ManufacturerTypeDisplay';
import { BasePage } from '../BasePage';
import { ManufacturerEditModal } from './Manufacturer/ManufacturerEditModal';
/**
 * Manufacturer Page
 */
export class Manufacturer extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'settings_manufacturer';
        this._manufacturerDialog = new ManufacturerEditModal(this._wrapper.getContentWrapper().getContent());
        this.setTitle(`${Lang.i().l('settings')} - ${Lang.i().l('manufacturer')}`);
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), new LangText('add_manufacturer'), () => {
            this._manufacturerDialog.resetValues();
            this._manufacturerDialog.setTitle(Lang.i().l('add_manufacturer'));
            this._manufacturerDialog.show();
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
        // -------------------------------------------------------------------------------------------------------------
        this._manufacturerDialog.setOnSave(async () => {
            try {
                const data = {
                    id: this._manufacturerDialog.getId(),
                    name: this._manufacturerDialog.getName(),
                    type: this._manufacturerDialog.getType()
                };
                if (await SettingApi.saveManufacturer(data)) {
                    this._manufacturerDialog.hide();
                    if (this._onLoadTable) {
                        this._onLoadTable();
                    }
                    this._toast.fire({
                        icon: 'success',
                        title: 'Manufacturer save success.'
                    });
                }
            }
            catch (message) {
                this._toast.fire({
                    icon: 'error',
                    title: message
                });
            }
        });
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            const row1 = new ContentRow(this._wrapper.getContentWrapper().getContent());
            const card = new Card(new ContentCol(row1, ContentColSize.col12));
            card.setTitle(new LangText('manufacturer'));
            card.showLoading();
            const table = new Table(card.getElement());
            const trhead = new Tr(table.getThead());
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('ID')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('name')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('type')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('action')
            ]));
            const manufacturers = await ErrorDialog.callTry(() => {
                return SettingApi.getManufacturerList();
            });
            if (manufacturers !== null) {
                for (const manufacturer of manufacturers) {
                    const trbody = new Tr(table.getTbody());
                    // eslint-disable-next-line no-new
                    new Td(trbody, `#${manufacturer.id}`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${manufacturer.name}`);
                    const typeTd = new Td(trbody, '');
                    // eslint-disable-next-line no-new
                    new ManufacturerTypeDisplay(typeTd, manufacturer.type);
                    // action
                    const actionTd = new Td(trbody, '');
                    const btnMenu = new ButtonMenu(actionTd, IconFa.bars, true, ButtonType.borderless);
                    btnMenu.addMenuItem('Edit', () => {
                        this._manufacturerDialog.resetValues();
                        this._manufacturerDialog.setTitle(Lang.i().l('edit_manufacturer'));
                        this._manufacturerDialog.setId(manufacturer.id);
                        this._manufacturerDialog.setName(manufacturer.name);
                        this._manufacturerDialog.setType(manufacturer.type);
                        this._manufacturerDialog.show();
                    }, IconFa.edit);
                }
            }
            card.hideLoading();
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
