import { CardBodyType, CardLine, CardType, CollectionCardWidget } from 'bambooo';
import { Lang } from '../../../Lang/Lang';
import { VehicleTypesTaximeterEntry } from './VehicleTypesTaximeterEntry';
/**
 * Vehicle types taximeter list
 */
export class VehicleTypesTaximeterList extends CollectionCardWidget {
    /**
     * Constructor
     * @param {Element|any} element
     * @param {boolean} editable
     */
    constructor(element, editable = false) {
        super({
            element,
            editable,
            entryClass: VehicleTypesTaximeterEntry,
            bodyType: CardBodyType.none,
            cardType: CardType.none,
            cardLine: CardLine.none
        });
        /**
         * Taximeters
         * @protected
         */
        this._taximeters = [];
        this._element.setTitle(Lang.i().l('taximeter'));
    }
    /**
     * Create a new object
     * @protected
     * @returns {VehicleTypesTaximeterEntry}
     */
    _createObject() {
        const entry = new VehicleTypesTaximeterEntry(this, this._editable);
        entry.setTaximeterList(this._taximeters);
        return entry;
    }
    /**
     * set the taximeter list
     * @param {Taximeter[]} taximeters
     */
    setTaximeterList(taximeters) {
        this._taximeters = taximeters;
    }
    /**
     * Set the vehicle types taximeter list
     * @param {VehicleTaximeter[]} vtts
     */
    setList(vtts) {
        this.removeAll();
        for (const vtt of vtts) {
            const vtte = this._createObject();
            vtte.setVehicleTypesTaximeter(vtt);
            this.addObject(vtte);
        }
    }
    /**
     * Return the cehicle type taximeter list
     * @returns {VehicleTaximeter[]}
     */
    getList() {
        const list = [];
        for (const entry of this._objects) {
            list.push(entry.getVehicleTypesTaximeter());
        }
        return list;
    }
}
