/**
 * Lang_DE
 */
export class Lang_DE {
    constructor() {
        /**
         * lang content
         * @private
         */
        this._content = {
            'addressbook': 'Adressbuch',
            'calendar': 'Kalender',
            'settings': 'Einstellungen',
            'edit': 'Bearbeiten',
            'contact_title': 'Titel',
            'person': 'Person',
            'company': 'Firma',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
            'birthday': 'Geburstag',
            'private': 'Privat',
            'companyname': 'Name der Firma',
            'address': 'Adresse',
            'phone': 'Telefon',
            'add_contact': 'Neuer Kontakt',
            'add_calendar': 'Neuer Termin',
            'calendar_title': 'Titel',
            'details': 'Details',
            'date': 'Datum',
            'time': 'Uhrzeit',
            'description': 'Beschreibung',
            'participant': 'Teilnehmer',
            'repeat': 'Wiederholung',
            'alert': 'Alarm',
            'location': 'Ort',
            'duration': 'Dauer',
            'category': 'Kategorie',
            'priority': 'Priorität',
            'My new Termin': 'Mein neuer Termin',
            'Save changes': 'Änderungen speichern',
            'Close': 'Schließen',
            'add_category': 'Neue Kategorie',
            'name': 'Name',
            'inquiry': 'Bestellungen',
            'positions': 'Positionen',
            'billing': 'Abrechnung',
            'shipping': 'Versand',
            'address 1': 'Adresse 1',
            'address 2': 'Adresse 2',
            'city': 'Stadt',
            'state': 'Bundesland',
            'postcode': 'PLZ',
            'country': 'Land',
            'overview': 'Übersicht',
            'id': 'Id',
            'status': 'Status',
            'total': 'Gesamt',
            'create': 'Erstellt',
            'modified': 'Verändert',
            'add_manufacturer': 'Neuer Hersteller',
            'type': 'Typ',
            'manufacturer': 'Hersteller',
            'users': 'Benutzer',
            'categories': 'Kategorien',
            'edit_manufacturer': 'Hersteller bearbeiten',
            'taximeter': 'Taxameter',
            'type approval': 'Bauartzulassung',
            'action': 'Aktion',
            'add_taximeter': 'Neuer Taxameter',
            'edit_taximeter': 'Taxameter bearbeiten',
            'please select a manufacturer': 'Bitte wähle einen Hersteller aus',
            'vehicle types': 'Fahrzeugtypen',
            'sensortype': 'Sensortyp',
            'sensortap': 'Sensor Abgriff',
            'sensor_articlenumber': 'Sensor Artikelnummer',
            'add_vehicletype': 'Neuer Fahrzeugtyp',
            'edit_vehicletype': 'Fahrzeugtyp bearbeiten',
            'vehicle': 'Fahrzeug',
            'allowed_taximeter': 'Erlaubte Taxameter',
            'article': 'Artikel'
        };
    }
    /**
     * getLangCode
     */
    getLangCode() {
        return 'de';
    }
    /**
     * getLangTitle
     */
    getLangTitle() {
        return 'Deutsch';
    }
    /**
     * getCountryCode
     */
    getCountryCode() {
        return 'de';
    }
    /**
     * l
     * @param acontent
     */
    l(acontent) {
        if (this._content[acontent]) {
            return this._content[acontent];
        }
        return null;
    }
    /**
     * getClassName
     */
    getClassName() {
        return 'Lang_DE';
    }
}
