import { SchemaContactListResponse, SchemaDefaultReturn } from 'kavula_schemas';
import { NetFetch } from '../Net/NetFetch';
/**
 * Contact API
 */
export class ContactApi {
    /**
     * Return the list with contacts
     * @returns {ContactListResponse}
     */
    static async getList() {
        return await NetFetch.postData('/json/v1/contact/list', {}, SchemaContactListResponse);
    }
    /**
     * Save a contact
     * @param {Contact} contact
     */
    static async save(contact) {
        const request = {
            contact
        };
        await NetFetch.postData('/json/v1/contact/save', request, SchemaDefaultReturn);
        return true;
    }
}
