import { LangText, NavbarLinkButton, NavbarLinkFullsize, NavbarLinkLanguage, SidebarMenuItem, SidebarMenuTree, UtilAvatarGenerator, UtilColor, UtilRedirect, UtilShorname } from 'bambooo';
import { Vts } from 'vts';
import { Lang_DE } from '../langs/Lang_DE';
import { Lang_EN } from '../langs/Lang_EN';
import { UserApi } from './Api/UserApi';
import { Lang } from './Lang/Lang';
import { HttpError } from './Net/Error/HttpError';
import { Addressbook as AddressbookPage } from './Pages/Addressbook';
import { LoginApi } from './Api/LoginApi';
import { Calendar as CalendarPage } from './Pages/Calendar';
import { Categories as CategoriesPage } from './Pages/Categories';
import { Dashboard as DashboardPage } from './Pages/Dashboard';
import { Error, Error as ErrorPage } from './Pages/Error';
import { Inquiry as InquiryPage } from './Pages/Inquiry';
import { Profil } from './Pages/Profil';
import { Settings as SettingsPage } from './Pages/Settings';
import { EMailServer as EMailServerPage } from './Pages/Settings/EMailServer';
import { Manufacturer } from './Pages/Settings/Manufacturer';
import { Taximeter } from './Pages/Settings/Taximeter';
import { VehicleTypes } from './Pages/Settings/VehicleTypes';
import { Woocommerce } from './Pages/Settings/Woocommerce';
import { Users as UsersPage } from './Pages/Users';
import { Wws as WwsPage } from './Pages/Wws';
import { WwsArticle as WwsArticlePage } from './Pages/Wws/WwsArticle';
/**
 * Page loader object, load pages, menu, bars etc ....
 */
export class PageLoader {
    /**
     * Return an instance from Pageloader
     */
    static getInstance() {
        if (PageLoader._instance === null) {
            PageLoader._instance = new PageLoader();
        }
        return PageLoader._instance;
    }
    /**
     * Page loader constructor
     */
    constructor() {
        /**
         * Current loaded page object
         * @protected
         */
        this._currentPage = null;
        /**
         * user page
         * @protected
         */
        this._userPage = Profil;
        /**
         * Side menu list
         * @protected
         */
        this._sideMenuList = [
            {
                title: 'Dashboard',
                icon: 'nav-icon fas fa-tachometer-alt',
                name: 'dashboard',
                page: DashboardPage
            },
            {
                title: new LangText('addressbook'),
                icon: 'fa-solid fa-address-card',
                name: 'addressbook',
                page: AddressbookPage
            },
            {
                title: new LangText('calendar'),
                icon: 'nav-icon far fa-calendar-alt',
                name: 'calendar',
                page: CalendarPage
            },
            {
                title: new LangText('inquiry'),
                icon: 'nav-icon fas fa-copy',
                name: 'inquiry',
                page: InquiryPage
            },
            {
                title: new LangText('Wws'),
                icon: 'nav-icon fas fa-warehouse',
                name: 'wws',
                page: WwsPage,
                items: [
                    {
                        title: new LangText('article'),
                        name: 'wws-article',
                        icon: 'fa-solid fa-tags',
                        page: WwsArticlePage
                    },
                ]
            },
            {
                title: new LangText('settings'),
                icon: 'fa-cogs',
                name: 'settings',
                page: SettingsPage,
                items: [
                    {
                        title: new LangText('users'),
                        name: 'users',
                        icon: 'fa-solid fa-users',
                        page: UsersPage
                    },
                    {
                        title: new LangText('categories'),
                        name: 'categories',
                        icon: 'fa-solid fa-tags',
                        page: CategoriesPage
                    },
                    {
                        title: 'EMail-Server',
                        name: 'settings_emailserver',
                        icon: 'nav-icon far fa-envelope',
                        page: EMailServerPage
                    },
                    {
                        title: 'WooCommerce',
                        name: 'settings_woocommerce',
                        icon: 'fa-cogs',
                        page: Woocommerce
                    },
                    {
                        title: new LangText('manufacturer'),
                        name: 'settings_manufacturer',
                        icon: 'nav-icon far fa-plus-square',
                        page: Manufacturer
                    },
                    {
                        title: new LangText('taximeter'),
                        name: 'settings_taximeter',
                        icon: 'nav-icon fas fa-tachometer-alt',
                        page: Taximeter
                    },
                    {
                        title: new LangText('vehicle types'),
                        name: 'settings_vehicle_types',
                        icon: 'nav-icon fas fa-car',
                        page: VehicleTypes
                    }
                ]
            }
        ];
        // init
        Lang.init([new Lang_EN(), new Lang_DE()]);
        Lang.i('Lang_DE');
        jQuery('#k_page_title').html(Lang.i().l('title'));
    }
    /**
     * Load Navbar
     * @param {BasePage} page
     * @protected
     */
    async _loadNavBar(page) {
        // right navbar --------------------------------------------------------------------------------------------
        const rightNavbar = page.getWrapper().getNavbar().getRightNavbar();
        // eslint-disable-next-line no-new
        const langNavbarLink = new NavbarLinkLanguage(rightNavbar);
        const changeLang = (lang) => {
            Lang.setStoreLangSelect(lang.getCountryCode());
            Lang.i().setCurrentLang(lang);
            Lang.i().lAll();
        };
        // english
        langNavbarLink.addLang(new Lang_EN(), changeLang);
        // germany
        langNavbarLink.addLang(new Lang_DE(), changeLang);
        const userSelectLang = Lang.getStoreLangSelect();
        if (userSelectLang) {
            langNavbarLink.setActiv(userSelectLang, true);
        }
        else {
            langNavbarLink.setActiv('us', true);
        }
        // eslint-disable-next-line no-new
        new NavbarLinkFullsize(rightNavbar.getElement());
        // eslint-disable-next-line no-new
        new NavbarLinkButton(rightNavbar.getElement(), 'fa-sign-out-alt', async () => {
            if (confirm('Logout?')) {
                await LoginApi.logout();
                UtilRedirect.toLogin();
            }
        });
    }
    /**
     * Load the sidemenu
     * @param {BasePage} page
     * @protected
     * @returns {void}
     */
    async _loadSidemenu(page) {
        const menu = page.getWrapper().getMainSidebar().getSidebar().getMenu();
        // eslint-disable-next-line consistent-this
        const pageLoader = this;
        for (const item of this._sideMenuList) {
            const menuItem = new SidebarMenuItem(menu);
            menuItem.setName(item.name);
            menuItem.setTitle(item.title);
            menuItem.setIconClass(item.icon);
            if (item.page) {
                menuItem.setClick(() => {
                    if (!Vts.isUndefined(item.page)) {
                        pageLoader.load(new item.page());
                    }
                });
            }
            let isSubActiv = false;
            if (item.items) {
                const menuTree = new SidebarMenuTree(menuItem);
                for (const sitem of item.items) {
                    const pmenuItem = new SidebarMenuItem(menuTree, true);
                    pmenuItem.setTitle(sitem.title);
                    pmenuItem.setName(sitem.name);
                    if (sitem.page) {
                        pmenuItem.setClick(() => {
                            if (!Vts.isUndefined(sitem.page)) {
                                pageLoader.load(new sitem.page());
                            }
                        });
                    }
                    if (sitem.icon) {
                        pmenuItem.setIconClass(sitem.icon);
                    }
                    if (page.getName() === sitem.name) {
                        pmenuItem.setActiv(true);
                        isSubActiv = true;
                    }
                }
            }
            if ((page.getName() === item.name) || isSubActiv) {
                menuItem.setActiv(true);
            }
        }
        menu.initTreeview();
    }
    /**
     * Load lang and replace placeholder
     * @protected
     */
    async _loadLang() {
        jQuery('#k_copyright').html(Lang.i().l('copyrightname'));
        jQuery('#k_version').html(Lang.i().l('version'));
        Lang.i().lAll();
    }
    /**
     * load the user information
     * @param page
     * @protected
     */
    async _loadUser(page) {
        var _a, _b;
        if (!await LoginApi.isLogin()) {
            UtilRedirect.toLogin();
        }
        const currentuser = await UserApi.getUserInfo();
        if (currentuser) {
            const up = page.getWrapper().getMainSidebar().getSidebar().getUserPanel();
            if (currentuser.user) {
                const user = currentuser.user;
                up.setImage(UtilAvatarGenerator.generateAvatar(UtilShorname.getShortname(user.username), 'white', UtilColor.getColor((_a = currentuser.user) === null || _a === void 0 ? void 0 : _a.username)));
                up.setUsername((_b = currentuser.user) === null || _b === void 0 ? void 0 : _b.username);
            }
            up.setOnClickFn(() => {
                this.load(new this._userPage());
            });
        }
    }
    /**
     * Load a page with access by public
     * @param {BasePage} page
     */
    async load(page) {
        const preloader = page.getWrapper().getPreloader();
        // eslint-disable-next-line consistent-this
        const pageLoader = this;
        try {
            let error = null;
            if (this._currentPage) {
                await this._currentPage.unloadContent();
            }
            page.setPageLoader(this);
            await this._loadNavBar(page);
            try {
                await this._loadUser(page);
            }
            catch (eUser) {
                if (Vts.isInstanceOf(eUser, Error)) {
                    error = eUser;
                }
            }
            await this._loadSidemenu(page);
            if (error !== null) {
                throw error;
            }
            await page.loadContent();
            preloader.readyLoad();
            this._currentPage = page;
            await this._loadLang();
        }
        catch (e) {
            if (e instanceof HttpError) {
                const errorPage = new ErrorPage(e.getStatus(), e.message);
                errorPage.setPageLoader(pageLoader);
                await errorPage.loadContent();
                await this._loadNavBar(errorPage);
                await this._loadSidemenu(errorPage);
                preloader.readyLoad();
                errorPage.getWrapper().getPreloader().readyLoad();
                this._currentPage = errorPage;
                // we throw for debuggin and can see more information in the browser
                throw e;
            }
        }
        return true;
    }
}
/**
 * Instance of IPageLoader
 * @private
 */
PageLoader._instance = null;
