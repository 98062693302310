import { ModalDialog, ModalDialogType, FormGroup, InputBottemBorderOnly2, FormRow, NavTab, Card, CardBodyType, LangText, InputType, Switch } from 'bambooo';
import { Lang } from '../../Lang/Lang';
import { AddressListWidget } from '../../Widget/Address/AddressListWidget';
/**
 * Contact edit modal dialog
 */
export class ContactEditModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'contactmodaldialog', ModalDialogType.large);
        /**
         * Unid from contact
         * @protected
         */
        this._unid = '';
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        this._navTab = new NavTab(this._body, 'contactnavtab');
        // person ------------------------------------------------------------------------------------------------------
        const tabPerson = this._navTab.addTab(new LangText('person'), 'contact_person');
        tabPerson.tab.show();
        const personCard = new Card(tabPerson.body, CardBodyType.none);
        personCard.getHeaderElement().hide();
        const rowPT = new FormRow(personCard);
        const groupPrefix = new FormGroup(rowPT.createCol(6), 'Prefix');
        this._inputPrefix = new InputBottemBorderOnly2(groupPrefix);
        this._inputPrefix.setPlaceholder('Dr.');
        const groupTitle = new FormGroup(rowPT.createCol(6), new LangText('contact_title'));
        this._inputTitle = new InputBottemBorderOnly2(groupTitle);
        this._inputTitle.setPlaceholder('Graf');
        const rowName = new FormRow(personCard);
        const groupFirstname = new FormGroup(rowName.createCol(6), new LangText('firstname'));
        this._inputFirstname = new InputBottemBorderOnly2(groupFirstname);
        this._inputFirstname.setPlaceholder('Max');
        const groupLastname = new FormGroup(rowName.createCol(6), new LangText('lastname'));
        this._inputLastname = new InputBottemBorderOnly2(groupLastname);
        this._inputLastname.setPlaceholder('Muster');
        const rowBP = new FormRow(personCard);
        const groupBday = new FormGroup(rowBP.createCol(6), new LangText('birthday'));
        this._inputBday = new InputBottemBorderOnly2(groupBday, 'contactbday', InputType.date);
        const groupPrivate = new FormGroup(rowBP.createCol(6), new LangText('private'));
        this._switchPrivate = new Switch(groupPrivate, 'contactprivate');
        // company -----------------------------------------------------------------------------------------------------
        const tabCompany = this._navTab.addTab(new LangText('company'), 'contact_company');
        const companyCard = new Card(tabCompany.body, CardBodyType.none);
        companyCard.getHeaderElement().hide();
        const groupOrgName = new FormGroup(companyCard, new LangText('companyname'));
        this._inputOrgName = new InputBottemBorderOnly2(groupOrgName);
        // address -----------------------------------------------------------------------------------------------------
        const tabAddress = this._navTab.addTab(new LangText('address'), 'contact_address');
        this._addressWidget = new AddressListWidget(tabAddress.body, true);
        // phone -------------------------------------------------------------------------------------------------------
        const tabPhone = this._navTab.addTab(new LangText('phone'), 'contact_phone');
        const phoneCard = new Card(tabPhone.body, CardBodyType.none);
        phoneCard.getHeaderElement().hide();
        // buttons -----------------------------------------------------------------------------------------------------
        jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
    }
    /**
     * Set the unid from a contact
     * @param {string} unid
     */
    setUnid(unid) {
        this._unid = unid;
    }
    /**
     * Return the unid from a contact
     * @returns {string}
     */
    getUnid() {
        return this._unid;
    }
    /**
     * Set the prefix
     * @param {string} prefix
     */
    setPrefix(prefix) {
        this._inputPrefix.setValue(prefix);
    }
    /**
     * Return the prefix
     * @returns {string}
     */
    getPrefix() {
        return this._inputPrefix.getValue();
    }
    /**
     * Set the title from contact
     * @param {string} title
     */
    setContactTitle(title) {
        this._inputTitle.setValue(title);
    }
    /**
     * Return the title form contact
     * @returns {string}
     */
    getContactTitle() {
        return this._inputTitle.getValue();
    }
    /**
     * Set the firstname
     * @param {string} fn
     */
    setFirstname(fn) {
        this._inputFirstname.setValue(fn);
    }
    /**
     * Return the firstname
     * @returns {string}
     */
    getFirstname() {
        return this._inputFirstname.getValue();
    }
    /**
     * Set the lastname
     * @param {string} ln
     */
    setLastname(ln) {
        this._inputLastname.setValue(ln);
    }
    /**
     * Get the lastname
     * @returns {string}
     */
    getLastname() {
        return this._inputLastname.getValue();
    }
    /**
     * Set the bday
     * @param {string} bday
     */
    setBday(bday) {
        this._inputBday.setValue(bday);
    }
    /**
     * Return the Bday
     * @returns {string}
     */
    getBday() {
        return this._inputBday.getValue();
    }
    /**
     * Set the contact private
     * @param {boolean} cPrivate
     */
    setPrivate(cPrivate) {
        this._switchPrivate.setEnable(cPrivate);
    }
    /**
     * Return is contact private
     * @returns {boolean}
     */
    getPrivate() {
        return this._switchPrivate.isEnable();
    }
    /**
     * Set the org name
     * @param {string} orgname
     */
    setOrgName(orgname) {
        this._inputOrgName.setValue(orgname);
    }
    /**
     * Return the org name
     * @returns {string}
     */
    getOrgName() {
        return this._inputOrgName.getValue();
    }
    /**
     * Set the address list
     * @param {ContactAddress[]} list
     */
    setAddressList(list) {
        this._addressWidget.setAddressList(list);
    }
    /**
     * Return the address list
     * @returns {ContactAddress[]}
     */
    getAddressList() {
        return this._addressWidget.getAddressList();
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setUnid('');
        this.setPrefix('');
        this.setContactTitle('');
        this.setFirstname('');
        this.setLastname('');
        this.setBday('');
        this.setPrivate(false);
        this.setOrgName('');
        this.setAddressList([]);
        this._navTab.setTabSelect(0);
    }
    /**
     * set on save event
     * @param onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
