import { StatusCodes } from 'kavula_schemas';
import { Vts } from 'vts';
import { InternalError } from './Error/InternalError';
import { SchemaError } from './Error/SchemaError';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { UnknownResponse } from './Error/UnknownResponse';
/**
 * Response
 */
export class Response {
    /**
     * isSchemaValidate
     * @param schema
     * @param data
     */
    static isSchemaValidate(schema, data) {
        const errors = [];
        if (!schema.validate(data, errors)) {
            throw new SchemaError(errors);
        }
        return true;
    }
    /**
     * isResponse
     * @param schema
     * @param data
     */
    static isResponse(schema, data) {
        if (Response.isSchemaValidate(schema, data)) {
            switch (data.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
                case StatusCodes.INTERNAL_ERROR:
                    if (Vts.isUndefined(data.msg)) {
                        throw new InternalError('');
                    }
                    throw new InternalError(data.msg);
            }
            throw new UnknownResponse();
        }
        return true;
    }
}
