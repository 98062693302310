import { ButtonMenu, ButtonMenuPosition, ButtonType, CardBodyType, CardLine, CardType, CollectionCardWidget, IconFa } from 'bambooo';
import { ParticipantType } from 'kavula_schemas';
import { ParticipantWidget } from './ParticipantWidget';
/**
 * Participant list widget
 */
export class ParticipantListWidget extends CollectionCardWidget {
    /**
     * Constructor
     * @param {Element|any} element
     * @param {boolean} editable
     */
    constructor(element, editable = false) {
        super({
            element,
            editable,
            entryClass: ParticipantWidget,
            bodyType: CardBodyType.none,
            cardType: CardType.none,
            cardLine: CardLine.none
        });
        this._element.setTitle('Participants');
    }
    _createAddBtn(element) {
        const btnMenu = new ButtonMenu(element, IconFa.add, false, ButtonType.borderless, ButtonMenuPosition.none);
        btnMenu.addMenuItem('Address', () => {
            const participant = this._createObject();
            participant.setType(ParticipantType.address);
            this._objects.push(participant);
        });
        btnMenu.addMenuItem('New Address', () => {
            const participant = this._createObject();
            participant.setType(ParticipantType.newaddress);
            this._objects.push(participant);
        });
        btnMenu.addMenuItem('EMail', () => {
            const participant = this._createObject();
            participant.setType(ParticipantType.email);
            this._objects.push(participant);
        });
        btnMenu.addMenuItem('Text', () => {
            const participant = this._createObject();
            participant.setType(ParticipantType.text);
            this._objects.push(participant);
        });
    }
    /**
     * Create object
     * @protected
     */
    _createObject() {
        return new ParticipantWidget(this, this._editable);
    }
}
