import { Wrapper, LeftNavbarPushmenu } from 'bambooo';
/**
 * BasePage
 */
export class BasePage {
    /**
     * constructor
     */
    constructor() {
        /**
         * TITLE
         * @private
         */
        this.TITLE = 'Kavula';
        /**
         * LOGO
         * @private
         */
        this.LOGO = 'images/icons/icon-100x100.png';
        /**
         * wrapper
         * @protected
         */
        this._wrapper = new Wrapper();
        /**
         * page name
         * @protected
         */
        this._name = 'base';
        /**
         * load page fn
         * @protected
         */
        this._pageLoader = null;
        /**
         * on load table
         * @protected
         */
        this._onLoadTable = null;
        // eslint-disable-next-line no-new
        new LeftNavbarPushmenu(this._wrapper.getNavbar().getLeftNavbar());
        const preloader = this._wrapper.getPreloader();
        preloader.setTitle(this.TITLE);
        preloader.setImage(this.LOGO);
        const mainSidebar = this._wrapper.getMainSidebar();
        mainSidebar.getLogo().setTitle(this.TITLE);
        mainSidebar.getLogo().setImage(this.LOGO);
        // -------------------------------------------------------------------------------------------------------------
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this._toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        });
    }
    /**
     * getWrapper
     */
    getWrapper() {
        return this._wrapper;
    }
    /**
     * getName
     */
    getName() {
        return this._name;
    }
    /**
     * setTitle
     * @param {string|LangText} title
     * @protected
     */
    setTitle(title) {
        this._wrapper.getContentWrapper().getContentHeader().setTitle(title);
    }
    /**
     * loadContent
     */
    async loadContent() {
        // load content overwrite
    }
    /**
     * unloadContent
     */
    async unloadContent() {
        // unload content overwrite
    }
    /**
     * setPageLoader
     * @param loader
     */
    setPageLoader(loader) {
        this._pageLoader = loader;
    }
}
