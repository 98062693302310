import { Element } from 'bambooo';
/**
 * Search widget
 */
export class SearchWidget extends Element {
    /**
     * Contructor
     * @param {Element|any} element
     */
    constructor(element) {
        super(element);
        /**
         * Select options
         * @protected
         */
        this._selectOptions = {
            theme: 'bootstrap4',
            placeholder: '',
            data: null,
            ajax: null,
            allowClear: false,
            maximumSelectionLength: 1,
            templateResult: (entryData) => {
                return entryData.text;
            },
            templateSelection: (entryData) => {
                return entryData.text;
            }
        };
        this._select = jQuery('<select class="select2bs4" multiple="multiple" style="width: 100%;">').appendTo(this._element);
        this._updateSelect();
    }
    /**
     * Update the select
     * @protected
     */
    _updateSelect() {
        this._select.select2(this._selectOptions);
    }
    /**
     * setPlaceholder
     * @param {string} placeholder
     */
    setPlaceholder(placeholder) {
        this._selectOptions.placeholder = placeholder;
        this._updateSelect();
    }
    /**
     * Set data list for select
     * @param SearchWidgetData[] data
     */
    setData(data) {
        this._selectOptions.data = data;
        this._selectOptions.ajax = null;
        this._updateSelect();
    }
    /**
     * Set the request url
     * @param {string} url
     */
    setRequestUrl(url) {
        this._selectOptions.ajax = {
            url,
            dataType: 'json'
        };
        this._selectOptions.data = null;
        this._updateSelect();
    }
    /**
     * Set allow to clear
     * @param {boolean} allow
     */
    setAllowClear(allow) {
        this._selectOptions.allowClear = allow;
        this._updateSelect();
    }
    /**
     * Set the maximum selection length
     * @param {number} len
     */
    setMaximumSelectionLength(len) {
        this._selectOptions.maximumSelectionLength = len;
        this._updateSelect();
    }
    /**
     * Set the on template result
     * @param {SearchWidgetOnTemplateResult|null} on
     */
    setOnTemplateResult(on) {
        if (on === null) {
            this._selectOptions.templateResult = (entryData) => {
                return entryData.text;
            };
        }
        else {
            this._selectOptions.templateResult = on;
        }
        this._updateSelect();
    }
    /**
     * Set the on template selection
     * @param {SearchWidgetOnTemplateSelection|null} on
     */
    setOnTemplateSelection(on) {
        if (on === null) {
            this._selectOptions.templateSelection = (entryData) => {
                return entryData.text;
            };
        }
        else {
            this._selectOptions.templateSelection = on;
        }
        this._updateSelect();
    }
    /**
     * setOnSelect
     * @param {SearchWidgetOnEvent} on
     */
    setOnSelect(on) {
        this._select.unbind('select2:select').on('select2:select', on);
    }
    setOnUnselect(on) {
        this._select.unbind('select2:unselect').on('select2:unselect', on);
    }
    addClass(aclass) {
        jQuery(this._select.data('select2').$container).addClass(aclass);
    }
    removeClass(aclass) {
        jQuery(this._select.data('select2').$container).removeClass(aclass);
    }
    getValue() {
        return this._select.select2('data');
    }
}
