import { FormGroup, FormRow, InputBottemBorderOnly2, InputType, ModalDialog, ModalDialogType, Switch } from 'bambooo';
export class UsersEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'usersmodaldialog', ModalDialogType.large);
        /**
         * unid of entry
         * @protected
         */
        this._unid = '';
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupUsername = new FormGroup(bodyCard, 'Username');
        this._inputUsername = new InputBottemBorderOnly2(groupUsername);
        this._inputUsername.setPlaceholder('Name of user');
        const groupFullname = new FormGroup(bodyCard, 'Fullname');
        this._inputFullname = new InputBottemBorderOnly2(groupFullname);
        this._inputFullname.setPlaceholder('Fullname of user');
        const groupPassword = new FormGroup(bodyCard, 'Password');
        this._inputPassword = new InputBottemBorderOnly2(groupPassword, undefined, InputType.password);
        this._inputPassword.setPlaceholder('');
        const groupPasswordRepeat = new FormGroup(bodyCard, 'Password repeat');
        this._inputPasswordRepeat = new InputBottemBorderOnly2(groupPasswordRepeat, undefined, InputType.password);
        this._inputPasswordRepeat.setPlaceholder('');
        const groupEMail = new FormGroup(bodyCard, 'EMail');
        this._inputEMail = new InputBottemBorderOnly2(groupEMail);
        this._inputEMail.setPlaceholder('e@mail.org');
        const rowOpt = new FormRow(bodyCard);
        const groupIsDisabled = new FormGroup(rowOpt.createCol(4), 'Is Disabled');
        this._switchIsDisabled = new Switch(groupIsDisabled, 'userisdisabled');
        jQuery('<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>').appendTo(this._footer);
        const btnSave = jQuery('<button type="button" class="btn btn-primary">Save</button>').appendTo(this._footer);
        btnSave.on('click', () => {
            if (this._onSaveClick !== null) {
                this._onSaveClick();
            }
        });
    }
    /**
     * getUnid
     */
    getUnid() {
        return this._unid;
    }
    /**
     * setUnid
     * @param id
     */
    setUnid(id) {
        this._unid = id;
    }
    /**
     * getUsername
     */
    getUsername() {
        return this._inputUsername.getValue();
    }
    /**
     * setUsername
     * @param username
     */
    setUsername(username) {
        this._inputUsername.setValue(username);
    }
    /**
     * getFullname
     */
    getFullname() {
        return this._inputFullname.getValue();
    }
    /**
     * setFullname
     * @param fullname
     */
    setFullname(fullname) {
        this._inputFullname.setValue(fullname);
    }
    /**
     * getPassword
     */
    getPassword() {
        return this._inputPassword.getValue();
    }
    /**
     * setPassword
     * @param password
     */
    setPassword(password) {
        this._inputPassword.setValue(password);
    }
    /**
     * getPasswordRepeat
     */
    getPasswordRepeat() {
        return this._inputPasswordRepeat.getValue();
    }
    /**
     * setPasswordRepeat
     * @param password
     */
    setPasswordRepeat(password) {
        this._inputPasswordRepeat.setValue(password);
    }
    /**
     * getEMail
     */
    getEMail() {
        return this._inputEMail.getValue();
    }
    /**
     * setEMail
     * @param email
     */
    setEMail(email) {
        this._inputEMail.setValue(email);
    }
    /**
     * setIsDisabled
     * @param isDisabled
     */
    setIsDisabled(isDisabled) {
        this._switchIsDisabled.setEnable(isDisabled);
    }
    /**
     * getIsDisabled
     */
    getIsDisabled() {
        return this._switchIsDisabled.isEnable();
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setUnid('');
        this.setUsername('');
        this.setFullname('');
        this.setEMail('');
        this.setPassword('');
        this.setPasswordRepeat('');
        this.setIsDisabled(false);
    }
    /**
     * setOnSave
     * @param onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
