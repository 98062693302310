import { ButtonMenu, ButtonType, Card, ColumnContent, ContentCol, ContentColSize, ContentRow, IconFa, LangText, LeftNavbarLink, Table, Td, Th, Tr, UtilAvatarGenerator } from 'bambooo';
import { CategoriesApi } from '../Api/CategoriesApi';
import { Lang } from '../Lang/Lang';
import { ErrorDialog } from '../Widget/ErrorDialog';
import { BasePage } from './BasePage';
import { CategoryEditModal } from './Categories/CategoryEditModal';
/**
 * Categories Page
 */
export class Categories extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'categories';
        this.setTitle(`${Lang.i().l('settings')} - ${Lang.i().l('categories')}`);
        // dialogs modal -----------------------------------------------------------------------------------------------
        this._categoryDialog = new CategoryEditModal(this._wrapper.getContentWrapper().getContent().getElement());
        // Navbar Left -------------------------------------------------------------------------------------------------
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), new LangText('add_category'), async () => {
            this._categoryDialog.resetValues();
            this._categoryDialog.setTitle(Lang.i().l('add_category'));
            this._categoryDialog.show();
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
        // -------------------------------------------------------------------------------------------------------------
        this._categoryDialog.setOnSave(async () => {
            try {
                const category = {
                    uid: this._categoryDialog.getUnid(),
                    color: this._categoryDialog.getColor(),
                    name: this._categoryDialog.getName()
                };
                if (await CategoriesApi.save(category)) {
                    this._categoryDialog.hide();
                    if (this._onLoadTable) {
                        this._onLoadTable();
                    }
                    this._toast.fire({
                        icon: 'success',
                        title: 'Contact save success.'
                    });
                }
            }
            catch (message) {
                this._toast.fire({
                    icon: 'error',
                    title: message
                });
            }
        });
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            const row1 = new ContentRow(this._wrapper.getContentWrapper().getContent());
            const card = new Card(new ContentCol(row1, ContentColSize.col12));
            card.setTitle(new LangText('Categories'));
            card.showLoading();
            const table = new Table(card.getElement());
            const trhead = new Tr(table.getThead());
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Color'),
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Name'),
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, '');
            const categories = await ErrorDialog.callTry(() => {
                return CategoriesApi.getList();
            });
            if (categories && categories.list) {
                for (const category of categories.list) {
                    const trbody = new Tr(table.getTbody());
                    const avatarImg = UtilAvatarGenerator.generateAvatar('', 'white', category.color);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `<div class="image"><img src="${avatarImg}" class="img-circle elevation-2" alt="Color" width="33px"></div>`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, `${category.name}`);
                    // action
                    const actionTd = new Td(trbody, '');
                    const btnMenu = new ButtonMenu(actionTd, IconFa.bars, true, ButtonType.borderless);
                    btnMenu.addMenuItem('Edit', () => {
                        this._categoryDialog.resetValues();
                        this._categoryDialog.setTitle(Lang.i().l('Category Edit'));
                        this._categoryDialog.setUnid(category.uid);
                        this._categoryDialog.setName(category.name);
                        this._categoryDialog.setColor(category.color);
                        this._categoryDialog.show();
                    }, IconFa.edit);
                }
            }
            card.hideLoading();
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
