import { ButtonClass, ButtonDefault, ButtonDefaultType, Card, CardBodyType, CardType, FormGroup, FormRow, LangText, SelectBottemBorderOnly2 } from 'bambooo';
import { ConformityTypes } from 'kavula_schemas';
/**
 * Vehicle types taximeter entry
 */
export class VehicleTypesTaximeterEntry extends Card {
    /**
     * Constructor
     * @param {VehicleTypesTaximeterList} element
     * @param {boolean} editable
     */
    constructor(element, editable = false) {
        super(element.getElement(), CardBodyType.none, CardType.success);
        /**
         * Id
         * @protected
         */
        this._id = 0;
        const row = new FormRow(this);
        const groupTaximeter = new FormGroup(row.createCol(6), new LangText('Taximeter'));
        this._selectTaximeter = new SelectBottemBorderOnly2(groupTaximeter);
        const groupConformity = new FormGroup(row.createCol(6), new LangText('Conformity'));
        this._selectConformity = new SelectBottemBorderOnly2(groupConformity);
        this._selectConformity.addValue({
            key: `${ConformityTypes.conformity}`,
            value: 'Conformity',
        });
        this._selectConformity.addValue({
            key: `${ConformityTypes.d}`,
            value: 'D',
        });
        this._selectConformity.addValue({
            key: `${ConformityTypes.d1}`,
            value: 'D1',
        });
        this._selectConformity.addValue({
            key: `${ConformityTypes.dw}`,
            value: 'D-Wegstreckenzähler',
        });
        // Default
        this._selectConformity.setSelectedValue(`${ConformityTypes.conformity}`);
        // buttons -----------------------------------------------------------------------------------------------------
        const removeUpstreamBtn = new ButtonDefault(this.getToolsElement(), '', 'fa-trash', ButtonClass.tool, ButtonDefaultType.none);
        removeUpstreamBtn.setOnClickFn(() => {
            element.removeObject(this);
        });
        this.setReadOnly(!editable);
    }
    setId(id) {
        this._id = id;
    }
    getId() {
        return this._id;
    }
    /**
     * set the taximeter list
     * @param {Taximeter[]} taximeters
     */
    setTaximeterList(taximeters) {
        this._selectTaximeter.clearValues();
        this._selectTaximeter.addValue({
            key: '0',
            value: 'Please select'
        });
        for (const taximeter of taximeters) {
            this._selectTaximeter.addValue({
                key: `${taximeter.id}`,
                value: `${taximeter.name}`
            });
        }
    }
    /**
     * Set taximeter
     * @param {number} taxid
     */
    setTaximeter(taxid) {
        this._selectTaximeter.setSelectedValue(`${taxid}`);
    }
    /**
     * Return the taximeter
     * @returns {number}
     */
    getTaximeter() {
        return parseInt(this._selectTaximeter.getSelectedValue(), 10) || 0;
    }
    /**
     * Set conformity type
     * @param type
     */
    setConformityType(type) {
        this._selectConformity.setSelectedValue(`${type}`);
    }
    /**
     * Return the conformity type
     * @returns {number}
     */
    getConformityType() {
        return parseInt(this._selectConformity.getSelectedValue(), 10) || 0;
    }
    /**
     * Set all input/selects
     * @param vtt
     */
    setVehicleTypesTaximeter(vtt) {
        this.setId(vtt.id);
        this.setTaximeter(vtt.taximeter_id);
        this.setConformityType(vtt.conformity_type);
    }
    /**
     * Get all input selects
     * @returns {VehicleTaximeter}
     */
    getVehicleTypesTaximeter() {
        return {
            id: this._id,
            taximeter_id: this.getTaximeter(),
            vehicle_types_id: 0,
            conformity_type: this.getConformityType()
        };
    }
    /**
     * Set readonly
     * @param readOnly
     */
    setReadOnly(readOnly) {
        console.log(readOnly);
    }
    /**
     * Remove this element
     */
    remove() {
        this._element.remove();
    }
}
