import { Lang } from '../Lang/Lang';
import { BasePage } from './BasePage';
/**
 * Wws
 */
export class Wws extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'wws';
        this.setTitle(`${Lang.i().l('wws')}`);
    }
}
