/**
 * UnknownResponse
 */
export class UnknownResponse extends Error {
    /**
     * constructor
     * @param message
     */
    constructor(message) {
        let msg = '';
        if (message) {
            msg = message;
        }
        super(msg);
    }
}
