import { Badge, BadgeType, ButtonMenu, ButtonType, Card, ColumnContent, ContentCol, ContentColSize, ContentRow, IconFa, LangText, LeftNavbarLink, Switch, Table, Td, Th, Tr, UtilAvatarGenerator, UtilColor, UtilShorname } from 'bambooo';
import { UserApi } from '../Api/UserApi';
import { Lang } from '../Lang/Lang';
import { ErrorDialog } from '../Widget/ErrorDialog';
import { BasePage } from './BasePage';
import { UsersEditModal } from './Users/UsersEditModal';
/**
 * Users
 */
export class Users extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'users';
        this.setTitle(`${Lang.i().l('settings')} - ${Lang.i().l('users')}`);
        // dialogs modal -----------------------------------------------------------------------------------------------
        this._usersDialog = new UsersEditModal(this._wrapper.getContentWrapper().getContent().getElement());
        // Navbar Left -------------------------------------------------------------------------------------------------
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), 'Add User', async () => {
            this._usersDialog.resetValues();
            this._usersDialog.setTitle('Add User');
            this._usersDialog.show();
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
        // users dialog save -------------------------------------------------------------------------------------------
        this._usersDialog.setOnSave(async () => {
            try {
                const userEntry = {
                    unid: this._usersDialog.getUnid(),
                    username: this._usersDialog.getUsername(),
                    email: this._usersDialog.getEMail(),
                    password: this._usersDialog.getPassword(),
                    password_repeat: this._usersDialog.getPasswordRepeat(),
                    disable: this._usersDialog.getIsDisabled()
                };
                if (await UserApi.saveUser(userEntry)) {
                    this._usersDialog.hide();
                    if (this._onLoadTable) {
                        this._onLoadTable();
                    }
                    this._toast.fire({
                        icon: 'success',
                        title: 'Contact save success.'
                    });
                }
            }
            catch (message) {
                this._toast.fire({
                    icon: 'error',
                    title: message
                });
            }
        });
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            const row1 = new ContentRow(this._wrapper.getContentWrapper().getContent());
            const card = new Card(new ContentCol(row1, ContentColSize.col12));
            card.setTitle(new LangText('Users'));
            card.showLoading();
            const users = await ErrorDialog.callTry(() => {
                return UserApi.getUserList();
            });
            const table = new Table(card.getElement());
            const trhead = new Tr(table.getThead());
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Avatar')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Username'),
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('EMail')
            ]));
            // eslint-disable-next-line no-new
            const thDisabled = new Th(trhead, new LangText('Disabled'));
            const filterDisabled = new Switch(thDisabled, 'filterDisabled', 'Show all');
            filterDisabled.getElement().css({
                'margin-bottom': '0'
            });
            // eslint-disable-next-line no-new
            new Th(trhead, '');
            if (users) {
                for (const user of users) {
                    const trbody = new Tr(table.getTbody());
                    const avatarImg = UtilAvatarGenerator.generateAvatar(UtilShorname.getShortname(user.username), 'white', UtilColor.getColor(user.username));
                    // eslint-disable-next-line no-new
                    new Td(trbody, `<div class="image"><img src="${avatarImg}" class="img-circle elevation-2" alt="User Image" width="33px"></div>`);
                    // eslint-disable-next-line no-new
                    new Td(trbody, new ColumnContent([
                        `${user.username}`,
                    ]));
                    // eslint-disable-next-line no-new
                    new Td(trbody, '');
                    // eslint-disable-next-line no-new
                    const tdDisable = new Td(trbody, '');
                    if (user.disable) {
                        // eslint-disable-next-line no-new
                        new Badge(tdDisable, 'Yes', BadgeType.danger);
                    }
                    else {
                        // eslint-disable-next-line no-new
                        new Badge(tdDisable, 'No', BadgeType.success);
                    }
                    // action
                    const actionTd = new Td(trbody, '');
                    const btnMenu = new ButtonMenu(actionTd, IconFa.bars, true, ButtonType.borderless);
                    btnMenu.addMenuItem('Edit', () => {
                        this._usersDialog.resetValues();
                        this._usersDialog.setTitle('Edit User');
                        this._usersDialog.setUnid(user.unid);
                        this._usersDialog.setUsername(user.username);
                        this._usersDialog.setEMail(user.email);
                        this._usersDialog.setIsDisabled(user.disable);
                        this._usersDialog.show();
                    }, IconFa.edit);
                }
            }
            card.hideLoading();
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
