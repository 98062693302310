import { FormGroup, InputBottemBorderOnly2, LangText, ModalDialog, ModalDialogType, SelectBottemBorderOnly2 } from 'bambooo';
import { ManufacturerType } from 'kavula_schemas';
import { Lang } from '../../../Lang/Lang';
/**
 * MAnufacturer Edit Modal
 */
export class ManufacturerEditModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'manufacturermodaldialog', ModalDialogType.large);
        /**
         * Id
         * @protected
         */
        this._id = 0;
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        const groupName = new FormGroup(this._body, new LangText('name'));
        this._inputName = new InputBottemBorderOnly2(groupName);
        const groupType = new FormGroup(this._body, new LangText('type'));
        this._selectType = new SelectBottemBorderOnly2(groupType);
        this._selectType.addValue({
            key: `${ManufacturerType.taximeter}`,
            value: Lang.i().l('Taximeter')
        });
        this._selectType.addValue({
            key: `${ManufacturerType.automobile}`,
            value: Lang.i().l('Automobile')
        });
        // buttons -----------------------------------------------------------------------------------------------------
        jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
    }
    /**
     * Set an ID
     * @param {number} id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return an ID
     * @returns {number}
     */
    getId() {
        return this._id;
    }
    /**
     * Set Name
     * @param {string} name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * Return a Name
     * @returns {string}
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * Set type
     * @param {number} type
     */
    setType(type) {
        this._selectType.setSelectedValue(`${type}`);
    }
    /**
     * Return the type
     * @returns {number}
     */
    getType() {
        return parseInt(this._selectType.getSelectedValue(), 10) || 0;
    }
    /**
     * resetValues
     */
    resetValues() {
        this._id = 0;
        this._inputName.setValue('');
        this.setType(0);
    }
    /**
     * set on save event
     * @param onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
