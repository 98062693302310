import { SelectBottemBorderOnly2 } from 'bambooo';
import { CategoriesApi } from '../../Api/CategoriesApi';
/**
 * Category widget
 */
export class CategoryWidget extends SelectBottemBorderOnly2 {
    constructor(element) {
        super(element);
    }
    /**
     * loadCategories
     */
    async loadCategories() {
        const categories = await CategoriesApi.getList();
        if (categories.list) {
            this.clearValues();
            let index = 0;
            for (const category of categories.list) {
                let style;
                if (category.color !== '') {
                    style = `background-color:${category.color};`;
                }
                this.addValue({
                    key: category.uid,
                    value: category.name,
                    style
                });
                if (index === 0) {
                    this.setSelectedValue(category.uid);
                }
                index++;
            }
        }
    }
}
