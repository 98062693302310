import { ButtonDefault, Card, CardBodyType, FormRow, FormRowColType, IconFa, LangText, LeftNavbarLink, UtilAvatarGenerator, UtilColor, UtilShorname } from 'bambooo';
import { Vts } from 'vts';
import { ContactApi } from '../Api/ContactApi';
import { Lang } from '../Lang/Lang';
import { ErrorDialog } from '../Widget/ErrorDialog';
import { ContactEditModal } from './Addressbook/ContactEditModal';
import { BasePage } from './BasePage';
/**
 * Addressbook page
 */
export class Addressbook extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'addressbook';
        this._contactDialog = new ContactEditModal(this._wrapper.getContentWrapper().getContent());
        this.setTitle(new LangText('addressbook'));
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), new LangText('add_contact'), () => {
            this._contactDialog.resetValues();
            this._contactDialog.setTitle(Lang.i().l('add_contact'));
            this._contactDialog.show();
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
        // -------------------------------------------------------------------------------------------------------------
        this._contactDialog.setOnSave(async () => {
            try {
                const contact = {
                    id: this._contactDialog.getUnid(),
                    owner: '',
                    group_id: '',
                    cat_id: '',
                    n_prefix: this._contactDialog.getPrefix(),
                    title: this._contactDialog.getContactTitle(),
                    n_given: this._contactDialog.getFirstname(),
                    n_family: this._contactDialog.getLastname(),
                    n_middle: '',
                    n_suffix: '',
                    n_fn: '',
                    n_fileas: '',
                    bday: this._contactDialog.getBday(),
                    private: this._contactDialog.getPrivate(),
                    org_name: this._contactDialog.getOrgName(),
                    org_unit: '',
                    role: '',
                    assistent: '',
                    note: '',
                    addresses: this._contactDialog.getAddressList(),
                    phones: []
                };
                if (await ContactApi.save(contact)) {
                    this._contactDialog.hide();
                    if (this._onLoadTable) {
                        this._onLoadTable();
                    }
                    this._toast.fire({
                        icon: 'success',
                        title: 'Contact save success.'
                    });
                }
            }
            catch (message) {
                this._toast.fire({
                    icon: 'error',
                    title: message
                });
            }
        });
    }
    /**
     * loadContent
     */
    async loadContent() {
        const content = this._wrapper.getContentWrapper().getContent();
        const mainCard = new Card(content, CardBodyType.none);
        mainCard.getHeaderElement().hide();
        this._onLoadTable = async () => {
            mainCard.emptyBody();
            const contactResp = await ErrorDialog.callTry(() => {
                return ContactApi.getList();
            });
            if (Vts.isNull(contactResp)) {
                return;
            }
            if (contactResp.count === 0) {
                mainCard.getElement().addClass('text-center');
                mainCard.getElement().append('No contacts found.');
                return;
            }
            const row = new FormRow(mainCard);
            console.debug(contactResp.contacts);
            for (const contact of contactResp.contacts) {
                const card = new Card(row.createCol(12, FormRowColType.none, 'col-sm-6 col-md-4 d-flex align-items-stretch flex-column'), CardBodyType.none);
                card.getMainElement().addClass('bg-light d-flex flex-fill');
                card.getBodyElement().css({
                    'padding-top': '0px'
                });
                let orgTitle = 'None information set.';
                if (contact.private) {
                    orgTitle = 'Private contact';
                }
                if (contact.org_name !== '') {
                    orgTitle = `${contact.org_name}`;
                    if (contact.org_unit !== '') {
                        orgTitle = `${orgTitle} (${contact.org_unit})`;
                    }
                }
                card.getHeaderElement().addClass('text-muted border-bottom-0');
                card.setTitle(orgTitle);
                const bodyRow = new FormRow(card.getBodyElement());
                const bodyData = bodyRow.createCol(7, FormRowColType.none);
                bodyData.append(`<h2 class="lead"><b>${contact.n_fn}</b></h2>`);
                bodyData.append(`<p class="text-muted text-sm"><b>Note: </b> ${contact.note}</p>`);
                const listUl = jQuery('<ul class="ml-4 mb-0 fa-ul text-muted"></ul>').appendTo(bodyData);
                for (const address of contact.addresses) {
                    if (address.contact_main) {
                        const addressLi = jQuery('<li class="small"></li>').appendTo(listUl);
                        jQuery('<span class="fa-li"><i class="fas fa-lg fa-building"></i></span>').appendTo(addressLi);
                        addressLi.append(` Address: ${address.street}, ${address.locality} ${address.postalcode}, ${address.countryname}`);
                        break;
                    }
                }
                for (const phone of contact.phones) {
                    if (phone.contact_main) {
                        const phoneLi = jQuery('<li class="small"></li>').appendTo(listUl);
                        jQuery('<span class="fa-li"><i class="fas fa-lg fa-phone"></i></span>').appendTo(phoneLi);
                        phoneLi.append(` Phone: ${phone.phone_number}`);
                    }
                }
                const bodyImg = bodyRow.createCol(5, FormRowColType.none, 'text-center');
                jQuery(`<img src="${UtilAvatarGenerator.generateAvatar(UtilShorname.getShortname(contact.n_fn), 'white', UtilColor.getColor(contact.n_fn))}" alt="user-avatar" class="img-circle img-fluid" width="128px">`).appendTo(bodyImg);
                const btnGrp = jQuery('<div class="text-right"></div>').appendTo(card.getFooterElement());
                const btnEdit = new ButtonDefault(btnGrp, new LangText('edit'));
                btnEdit.setOnClickFn(() => {
                    this._contactDialog.resetValues();
                    this._contactDialog.setTitle(Lang.i().l('Contact Edit'));
                    this._contactDialog.setUnid(contact.id);
                    this._contactDialog.setPrefix(contact.n_prefix);
                    this._contactDialog.setContactTitle(contact.title);
                    this._contactDialog.setFirstname(contact.n_given);
                    this._contactDialog.setLastname(contact.n_family);
                    this._contactDialog.setBday(contact.bday);
                    this._contactDialog.setPrivate(contact.private);
                    this._contactDialog.setOrgName(contact.org_name);
                    this._contactDialog.setAddressList(contact.addresses);
                    this._contactDialog.show();
                });
            }
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
