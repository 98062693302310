import { Card, CardBodyType, FormGroup, FormRow, InputBottemBorderOnly2, InputType, LangText, ModalDialog, ModalDialogType, NavTab, SelectBottemBorderOnly2, Textarea } from 'bambooo';
import { EventPriority } from 'kavula_schemas/dist/src';
import moment from 'moment';
import { Lang } from '../../Lang/Lang';
import { CategoryWidget } from '../../Widget/Category/CategoryWidget';
import { ParticipantListWidget } from '../../Widget/Participant/ParticipantListWidget';
/**
 * Calendar edit modal dialog
 */
export class CalendarEditModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'calendarmodaldialog', ModalDialogType.large);
        /**
         * Unid from calendar
         * @protected
         */
        this._unid = '';
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        const groupTitle = new FormGroup(this._body, new LangText('calendar_title'));
        this._inputTitle = new InputBottemBorderOnly2(groupTitle);
        this._inputTitle.setPlaceholder(Lang.i().l('My new event'));
        const rowDate = new FormRow(this._body);
        const groupDate = new FormGroup(rowDate.createCol(4), new LangText('date'));
        this._inputDate = new InputBottemBorderOnly2(groupDate, 'caldate', InputType.date);
        const groupTime = new FormGroup(rowDate.createCol(4), new LangText('time'));
        this._inputTime = new InputBottemBorderOnly2(groupTime, 'caltime', InputType.time);
        const groupDuration = new FormGroup(rowDate.createCol(4), new LangText('duration'));
        this._selectDuration = new SelectBottemBorderOnly2(groupDuration);
        this._fillDuration();
        this._navTab = new NavTab(this._body, 'calendarnavtab');
        // details -----------------------------------------------------------------------------------------------------
        const tabDetails = this._navTab.addTab(new LangText('details'), 'calendar_date');
        const detailsCard = new Card(tabDetails.body, CardBodyType.none);
        detailsCard.getHeaderElement().hide();
        const rowLN = new FormRow(detailsCard);
        const groupPhone = new FormGroup(rowLN.createCol(6), new LangText('phone'));
        this._inputPhone = new InputBottemBorderOnly2(groupPhone);
        const groupLocation = new FormGroup(rowLN.createCol(6), new LangText('location'));
        this._inputLocation = new InputBottemBorderOnly2(groupLocation);
        const rowCP = new FormRow(detailsCard);
        const groupCategory = new FormGroup(rowCP.createCol(6), new LangText('category'));
        this._selectCategory = new CategoryWidget(groupCategory);
        this._selectCategory.loadCategories().then();
        const groupPriority = new FormGroup(rowCP.createCol(6), new LangText('priority'));
        this._selectPriority = new SelectBottemBorderOnly2(groupPriority);
        this._selectPriority.addValue({
            key: `${EventPriority.low}`,
            value: Lang.i().l('Low')
        });
        this._selectPriority.addValue({
            key: `${EventPriority.normal}`,
            value: Lang.i().l('Normal')
        });
        this._selectPriority.addValue({
            key: `${EventPriority.high}`,
            value: Lang.i().l('High')
        });
        const groupNote = new FormGroup(detailsCard, new LangText('description'));
        this._textareaDescription = new Textarea(groupNote);
        // participant -------------------------------------------------------------------------------------------------
        const tabParticipant = this._navTab.addTab(new LangText('participant'), 'calendar_user');
        this._participantsWidget = new ParticipantListWidget(tabParticipant.body, true);
        // repeat ------------------------------------------------------------------------------------------------------
        const tabRepeat = this._navTab.addTab(new LangText('repeat'), 'calendar_repeat');
        tabRepeat.body.empty();
        // alert -------------------------------------------------------------------------------------------------------
        const tabAlert = this._navTab.addTab(new LangText('alert'), 'calendar_alert');
        tabAlert.body.empty();
        // -------------------------------------------------------------------------------------------------------------
        const btnClose = jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
        btnClose.on('click', async () => {
            this.hide();
            if (this._onClose !== null) {
                await this._onClose(this);
            }
        });
    }
    _fillDuration() {
        this._selectDuration.clearValues();
        const shortDuration = ['0:15', '0:30', '0:45'];
        for (const entry of shortDuration) {
            this._selectDuration.addValue({
                key: `${entry}`,
                value: `${entry}`
            });
        }
        for (let i = 1; i < 25; i++) {
            this._selectDuration.addValue({
                key: `${i}:00`,
                value: `${i}:00`
            });
            this._selectDuration.addValue({
                key: `${i}:30`,
                value: `${i}:30`
            });
        }
    }
    /**
     * Set the unid from a contact
     * @param {string} unid
     */
    setUnid(unid) {
        this._unid = unid;
    }
    /**
     * Return the unid from a contact
     * @returns {string}
     */
    getUnid() {
        return this._unid;
    }
    /**
     * Set date
     * @param {string} date
     */
    setDate(date) {
        this._inputDate.setValue(date);
    }
    /**
     * Return the date
     */
    getDate() {
        const value = this._inputDate.getValue();
        const parts = value.split(' ');
        return parts[0];
    }
    /**
     * Set time
     * @param {string} time
     */
    setTime(time) {
        this._inputTime.setValue(time);
    }
    /**
     * Get the time
     * @returns {string}
     */
    getTime() {
        return this._inputTime.getValue();
    }
    /**
     * Set the duration
     * @param {string} duration
     */
    setDuration(duration) {
        this._selectDuration.setSelectedValue(duration);
    }
    /**
     * Get the duration
     * @returns {string}
     */
    getDuration() {
        return this._selectDuration.getSelectedValue();
    }
    /**
     * Set the category
     * @param {string} category
     */
    setCategory(category) {
        this._selectCategory.setSelectedValue(category);
    }
    /**
     * Get the category
     * @returns {string}
     */
    getCategory() {
        return this._selectCategory.getSelectedValue();
    }
    /**
     * Set the title from event
     * @param {string} title
     */
    setEventTitle(title) {
        this._inputTitle.setValue(title);
    }
    /**
     * Get the title from event
     * @returns {string}
     */
    getEventTitle() {
        return this._inputTitle.getValue();
    }
    /**
     * Set the descroption from event
     * @param {string} description
     */
    setDescription(description) {
        this._textareaDescription.setValue(description);
    }
    /**
     * Get the description from event
     * @returns {string}
     */
    getDescription() {
        return this._textareaDescription.getValue();
    }
    /**
     * Set the location
     * @param {string} location
     */
    setLocation(location) {
        this._inputLocation.setValue(location);
    }
    /**
     * Get the location
     * @returns {string}
     */
    getLocation() {
        return this._inputLocation.getValue();
    }
    /**
     * setPriority
     * @param {EventPriority|string} priority
     */
    setPriority(priority) {
        this._selectPriority.setSelectedValue(`${priority}`);
    }
    /**
     * Return the priority
     */
    getPriority() {
        switch (this._selectPriority.getSelectedValue()) {
            case '1':
                return EventPriority.low;
            case '3':
                return EventPriority.high;
            default:
                return EventPriority.normal;
        }
    }
    /**
     * Set the range start
     * @param {number} start
     */
    setRangeStart(start) {
        const range = moment(start);
        this.setDate(range.format('Y.MM.DD'));
        this.setTime(range.format('LT'));
    }
    /**
     * Get the range start
     * @returns {string}
     */
    getRangeStart() {
        const range = moment(`${this.getDate()} ${this.getTime()}`);
        return range.format();
    }
    /**
     * Set the range end
     * @param end
     */
    setRangeEnd(end) {
        const rangeEnd = moment(end);
        const rangeStart = this.getRangeStart();
        const hours = rangeEnd.diff(rangeStart, 'hours', true);
        console.log(hours);
        const parts = `${hours}`.split('.');
        let strData = `${parts[0]}`;
        if (parts.length > 1) {
            const percent = parseInt(parts[1], 10) || 0;
            if (percent <= 0) {
                strData += ':30';
            }
            else {
                const min = Math.round(60 * percent / 100);
                if (min < 10) {
                    strData += `:0${min}`;
                }
                else {
                    strData += `:${min}`;
                }
            }
        }
        else {
            strData += ':00';
        }
        if (!this._selectDuration.hasValue(strData)) {
            this._selectDuration.addValue({
                key: strData,
                value: strData
            });
        }
        this._selectDuration.setSelectedValue(strData);
    }
    /**
     * Get the range end
     * @returns {string}
     */
    getRangeEnd() {
        const [strHours, strMins] = this.getDuration().split(':');
        const hours = parseInt(strHours, 10) || 0;
        const mins = parseInt(strMins, 10) || 0;
        const range = moment(`${this.getDate()} ${this.getTime()}`);
        console.log(range.format());
        if (hours > 0) {
            range.add(hours, 'hours');
        }
        if (mins > 0) {
            range.add(mins, 'minutes');
        }
        console.log(range.format());
        return range.format();
    }
    /**
     * resetValues
     */
    async resetValues() {
        this.setUnid('');
        this.setCategory('');
        this._fillDuration();
        this.setDuration('0:15');
        this.setEventTitle('');
        this.setDescription('');
        this.setLocation('');
        this.setPriority(EventPriority.normal);
        await this._selectCategory.loadCategories();
    }
    /**
     * Set on save
     * @param {CalendarEditModalOnClickFn} onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
