import { SchemaCalendarEventResponse, SchemaCalendarListResponse, SchemaDefaultReturn } from 'kavula_schemas';
import { Vts } from 'vts';
import { UnknownResponse } from '../Net/Error/UnknownResponse';
import { NetFetch } from '../Net/NetFetch';
export class CalendarApi {
    /**
     * getEvent
     * @param {string} unid
     * @returns {CalendarEvent}
     */
    static async getEvent(unid) {
        const request = {
            unid
        };
        const response = await NetFetch.postData('/json/v1/calendar/event', request, SchemaCalendarEventResponse);
        if (Vts.isUndefined(response.event)) {
            throw new UnknownResponse('Event data is empty!');
        }
        return response.event;
    }
    static async getList(request) {
        const response = await NetFetch.postData('/json/v1/calendar/list', request, SchemaCalendarListResponse);
        return response.list;
    }
    /**
     * save
     * @param {CalendarEvent} event
     * @returns {boolean}
     */
    static async save(event) {
        const request = {
            event
        };
        await NetFetch.postData('/json/v1/calendar/save', request, SchemaDefaultReturn);
        return true;
    }
}
