import { ColumnContent, LangText, Table, Th, Tr } from 'bambooo';
export class InquiryPositionList extends Table {
    /**
     * Constructor
     * @param {any} elementObject
     */
    constructor(element) {
        super(element);
        // add head ----------------------------------------------------------------------------------------------------
        const trhead = new Tr(this.getThead());
        // eslint-disable-next-line no-new
        new Th(trhead, new ColumnContent([
            new LangText('Qty', 'inquiry_qty'),
        ]));
        // eslint-disable-next-line no-new
        new Th(trhead, new ColumnContent([
            new LangText('Product', 'inquiry_product'),
        ]));
        // eslint-disable-next-line no-new
        new Th(trhead, new ColumnContent([
            new LangText('Serial #', 'inquiry_serial'),
        ]));
        // eslint-disable-next-line no-new
        new Th(trhead, new ColumnContent([
            new LangText('Description', 'inquiry_desciption'),
        ]));
        // eslint-disable-next-line no-new
        new Th(trhead, new ColumnContent([
            new LangText('Subtotal', 'inquiry_subtotal'),
        ]));
        // eslint-disable-next-line no-new
        new Th(trhead, new ColumnContent([
            new LangText('', 'inquiry_action'),
        ]));
    }
}
