import { CardBodyType, CardLine, CardType, CollectionCardWidget } from 'bambooo';
import { ContactAddressType } from 'kavula_schemas';
import { Lang } from '../../Lang/Lang';
import { AddressWidget } from './AddressWidget';
/**
 * Address list widget
 */
export class AddressListWidget extends CollectionCardWidget {
    /**
     * Constructor
     * @param {Element|any} element
     * @param {boolean} editable
     */
    constructor(element, editable = false) {
        super({
            element,
            editable,
            entryClass: AddressWidget,
            bodyType: CardBodyType.none,
            cardType: CardType.none,
            cardLine: CardLine.none
        });
        this._element.setTitle(Lang.i().l('Addresses'));
    }
    /**
     * Create a new object
     * @protected
     */
    _createObject() {
        const addressW = new AddressWidget(this, this._editable);
        addressW.setAddress({
            address_type: ContactAddressType.private,
            contact_main: false,
            region: '',
            postalcode: '',
            locality: '',
            street2: '',
            street: '',
            id: '',
            contact_id: '',
            countryname: ''
        });
        return addressW;
    }
    /**
     * Set the address list
     * @param {ContactAddress[]} list
     */
    setAddressList(list) {
        this.removeAll();
        for (const address of list) {
            const addressW = new AddressWidget(this, this._editable);
            addressW.setAddress(address);
            this._objects.push(addressW);
        }
    }
    /**
     * Return the address list
     * @returns {ContactAddress[]}
     */
    getAddressList() {
        const list = [];
        for (const address of this._objects) {
            list.push(address.getAddress());
        }
        return list;
    }
    /**
     * removeAddress
     * @param {AddressWidget} address
     */
    removeAddress(address) {
        this.removeObject(address);
    }
}
