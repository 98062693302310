import { Calendar as FullCalendar } from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Card, CardBodyType, ContentCol, ContentColSize, ContentRow, IconFa, LangText, LeftNavbarLink } from 'bambooo';
import moment from 'moment';
import { Vts } from 'vts';
import { CalendarApi } from '../Api/CalendarApi';
import { CategoriesApi } from '../Api/CategoriesApi';
import { Lang } from '../Lang/Lang';
import { ErrorDialog } from '../Widget/ErrorDialog';
import { BasePage } from './BasePage';
import { CalendarEditModal } from './Calendar/CalendarEditModal';
/**
 * Calendar Page
 */
export class Calendar extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'calendar';
        /**
         * Calendar object
         * @protected
         */
        this._calendar = null;
        this._calendarDialog = new CalendarEditModal(this._wrapper.getContentWrapper().getContent());
        this.setTitle(new LangText('calendar'));
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), new LangText('add_calendar'), async () => {
            await this._calendarDialog.resetValues();
            this._calendarDialog.setTitle(Lang.i().l('add_calendar'));
            this._calendarDialog.show();
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
        // -------------------------------------------------------------------------------------------------------------
        this._calendarDialog.setOnSave(async () => {
            try {
                const event = {
                    unid: this._calendarDialog.getUnid(),
                    title: this._calendarDialog.getEventTitle(),
                    description: this._calendarDialog.getDescription(),
                    location: this._calendarDialog.getLocation(),
                    range_start: this._calendarDialog.getRangeStart(),
                    range_end: this._calendarDialog.getRangeEnd(),
                    modifier: '',
                    priority: this._calendarDialog.getPriority(),
                    category_unid: this._calendarDialog.getCategory(),
                    creator: '',
                    owner_unid: '',
                    tz_id: 0,
                    deleted: '',
                    created: '',
                    modified: '',
                    reference: 0
                };
                if (await CalendarApi.save(event)) {
                    this._calendarDialog.hide();
                    if (this._calendar) {
                        this._calendar.render();
                        this._calendar.removeAllEvents();
                        this._calendar.refetchEvents();
                    }
                    this._toast.fire({
                        icon: 'success',
                        title: 'Event save success.'
                    });
                }
            }
            catch (message) {
                this._toast.fire({
                    icon: 'error',
                    title: message
                });
            }
        });
        this._calendarDialog.setOnClose(async () => {
            if (this._calendar) {
                this._calendar.render();
                this._calendar.removeAllEvents();
                this._calendar.refetchEvents();
            }
        });
    }
    /**
     * Set the event to Modal Dialog
     * @param {CalendarEvent} event
     * @protected
     */
    async _eventToModalDialog(event) {
        await this._calendarDialog.resetValues();
        this._calendarDialog.setTitle(Lang.i().l('edit_calendar'));
        this._calendarDialog.show();
        this._calendarDialog.setUnid(event.unid);
        this._calendarDialog.setRangeStart(event.range_start);
        this._calendarDialog.setRangeEnd(event.range_end);
        this._calendarDialog.setEventTitle(event.title);
        this._calendarDialog.setDescription(event.description);
        this._calendarDialog.setCategory(event.category_unid);
        this._calendarDialog.setLocation(event.location);
        this._calendarDialog.setPriority(event.priority);
    }
    /**
     * loadContent
     */
    async loadContent() {
        const content = this._wrapper.getContentWrapper().getContent();
        const row = new ContentRow(content);
        const col1 = new ContentCol(row, ContentColSize.colMd2);
        const col2 = new ContentCol(row, ContentColSize.colMd10);
        const catCard = new Card(col1, CardBodyType.profile);
        jQuery('<button type="button" class="btn btn-tool" data-card-widget="collapse">\n' +
            '<i class="fas fa-minus"></i>\n' +
            '</button>').appendTo(catCard.getToolsElement()).on('click', () => {
            if (col1.getElement().hasClass(ContentColSize.colMd2)) {
                col1.getElement().removeClass(ContentColSize.colMd2);
                col1.getElement().addClass('col-md-1');
                col2.getElement().removeClass(ContentColSize.colMd10);
                col2.getElement().addClass('col-md-11');
            }
            else {
                col1.getElement().removeClass('col-md-1');
                col1.getElement().addClass(ContentColSize.colMd2);
                col2.getElement().removeClass('col-md-11');
                col2.getElement().addClass(ContentColSize.colMd10);
            }
            if (!Vts.isNull(this._calendar)) {
                this._calendar.render();
            }
        });
        catCard.setTitle(Lang.i().l('Events'));
        const eventsDiv = jQuery('<div />').appendTo(catCard.getBodyElement());
        const categories = await CategoriesApi.getList();
        if (categories.list) {
            for (const category of categories.list) {
                const catElement = jQuery(`<div class="external-event ui-draggable ui-draggable-handle" style="position: relative;background-color:${category.color};">${category.name}</div>`).appendTo(eventsDiv);
                catElement.data('unid', category.uid);
                catElement.data('name', category.name);
            }
        }
        // eslint-disable-next-line no-new
        new Draggable(eventsDiv[0], {
            itemSelector: '.external-event',
            eventData: (el) => {
                return {
                    title: el.innerText,
                    backgroundColor: window.getComputedStyle(el, null).getPropertyValue('background-color'),
                    borderColor: window.getComputedStyle(el, null).getPropertyValue('background-color'),
                    textColor: window.getComputedStyle(el, null).getPropertyValue('color'),
                };
            }
        });
        // -------------------------------------------------------------------------------------------------------------
        const mainCard = new Card(col2, CardBodyType.none);
        mainCard.getHeaderElement().hide();
        const calendarEl = jQuery('<div />').appendTo(mainCard.getBodyElement());
        const calOptions = {
            plugins: [momentPlugin, dayGridPlugin, timeGridPlugin, listPlugin, momentTimezonePlugin, interactionPlugin],
            initialView: 'timeGridWeek',
            weekNumbers: true,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'days5,days7 dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            customButtons: {
                days5: {
                    text: '5 Days week',
                    click: () => {
                        if (!Vts.isNull(this._calendar)) {
                            this._calendar.setOption('hiddenDays', [6, 0]);
                            this._calendar.render();
                        }
                    }
                },
                days7: {
                    text: '7 Days week',
                    click: () => {
                        if (!Vts.isNull(this._calendar)) {
                            this._calendar.setOption('hiddenDays', []);
                            this._calendar.render();
                        }
                    }
                }
            },
            hiddenDays: [6, 0],
            nowIndicator: true,
            navLinks: true,
            editable: true,
            selectable: true,
            dayMaxEvents: true,
            locales: allLocales,
            contentHeight: 620,
            slotDuration: '00:15:00',
            slotMinTime: '04:00:00',
            slotMaxTime: '20:00:00',
            slotLabelFormat: {
                hour: '2-digit',
                minute: '2-digit'
            },
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: '06:00',
                endTime: '18:00'
            },
            dateClick: async (info) => {
                await this._calendarDialog.resetValues();
                this._calendarDialog.setTitle(Lang.i().l('add_calendar'));
                this._calendarDialog.setDate(moment(info.dateStr).format('YYYY.MM.DD'));
                this._calendarDialog.setTime(moment(info.dateStr).format('h:mm a'));
                this._calendarDialog.show();
            },
            eventClick: async (info) => {
                if (info.event.id !== '') {
                    const event = await ErrorDialog.callTry(() => {
                        return CalendarApi.getEvent(info.event.id);
                    });
                    if (!Vts.isNull(event)) {
                        await this._eventToModalDialog(event);
                    }
                }
            },
            eventResize: async (info) => {
                if (info.event.id === '') {
                    info.revert();
                    return;
                }
                const event = await ErrorDialog.callTry(() => {
                    return CalendarApi.getEvent(info.event.id);
                });
                if (!Vts.isNull(event)) {
                    await this._eventToModalDialog(event);
                    if (Vts.isUndefined(info.event.start) || Vts.isNull(info.event.start)) {
                        this._calendarDialog.setRangeStart(event.range_start);
                    }
                    else {
                        this._calendarDialog.setRangeStart(info.event.start);
                    }
                    if (!(Vts.isUndefined(info.event.end) || Vts.isNull(info.event.end))) {
                        this._calendarDialog.setRangeEnd(info.event.end);
                    }
                    return;
                }
                info.revert();
            },
            eventDrop: async (info) => {
                if (info.event.id === '') {
                    info.revert();
                    return;
                }
                const event = await ErrorDialog.callTry(() => {
                    return CalendarApi.getEvent(info.event.id);
                });
                if (!Vts.isNull(event)) {
                    await this._eventToModalDialog(event);
                    if (Vts.isUndefined(info.event.start) || Vts.isNull(info.event.start)) {
                        this._calendarDialog.setRangeStart(event.range_start);
                    }
                    else {
                        this._calendarDialog.setRangeStart(info.event.start);
                    }
                    return;
                }
                info.revert();
            },
            events: async (arg, successCallback) => {
                const cateMap = new Map();
                const cates = await ErrorDialog.callTry(() => {
                    return CategoriesApi.getList();
                });
                if (!Vts.isNull(cates)) {
                    for (const cat of cates.list) {
                        cateMap.set(cat.uid, cat);
                    }
                }
                const events = await ErrorDialog.callTry(() => {
                    return CalendarApi.getList({
                        start: arg.startStr,
                        end: arg.endStr
                    });
                });
                console.log(events);
                if (Vts.isNull(events)) {
                    return;
                }
                const eventList = [];
                for (const entry of events) {
                    const startRange = moment(entry.range_start).toDate();
                    const endRange = moment(entry.range_end).toDate();
                    let color = '#ffffff';
                    if (cateMap.has(entry.category_unid)) {
                        const cate = cateMap.get(entry.category_unid);
                        if (!Vts.isUndefined(cate)) {
                            color = cate.color;
                        }
                    }
                    eventList.push({
                        title: entry.title,
                        start: startRange,
                        end: endRange,
                        allDay: false,
                        backgroundColor: color,
                        borderColor: color,
                        id: entry.unid
                    });
                }
                //const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                successCallback(eventList);
            },
            droppable: true,
            drop: async (info) => {
                const data = jQuery(info.draggedEl).data();
                await this._calendarDialog.resetValues();
                this._calendarDialog.setTitle(Lang.i().l('add_calendar'));
                this._calendarDialog.setDate(moment(info.dateStr).format('YYYY.MM.DD'));
                this._calendarDialog.setTime(moment(info.dateStr).format('h:mm a'));
                if (data.name) {
                    this._calendarDialog.setEventTitle(data.name);
                }
                if (data.unid) {
                    this._calendarDialog.setCategory(data.unid);
                }
                this._calendarDialog.show();
            }
        };
        this._calendar = new FullCalendar(calendarEl[0], calOptions);
        const langSelect = Lang.getStoreLangSelect();
        if (!Vts.isNull(langSelect)) {
            this._calendar.setOption('locale', langSelect);
        }
        this._calendar.render();
        jQuery(document).on('shown.lte.pushmenu', () => {
            setTimeout(() => {
                if (!Vts.isNull(this._calendar)) {
                    this._calendar.render();
                }
            }, 500);
        });
        jQuery(document).on('collapsed.lte.pushmenu', () => {
            setTimeout(() => {
                if (!Vts.isNull(this._calendar)) {
                    this._calendar.render();
                }
            }, 500);
        });
    }
}
