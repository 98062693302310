import { Card, ColumnContent, ContentCol, ContentColSize, ContentRow, LangText, Table, Th, Tr } from 'bambooo';
import { Lang } from '../../Lang/Lang';
import { BasePage } from '../BasePage';
/**
 * Wws Article Page
 */
export class WwsArticle extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'wws-article';
        this.setTitle(`${Lang.i().l('article')}`);
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            const row1 = new ContentRow(this._wrapper.getContentWrapper().getContent());
            const card = new Card(new ContentCol(row1, ContentColSize.col12));
            card.setTitle(new LangText('article'));
            //card.showLoading();
            const table = new Table(card.getElement());
            const trhead = new Tr(table.getThead());
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('ID')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Name')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('Action')
            ]));
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
