import { ButtonClass, ButtonDefault, ButtonDefaultType, Card, CardBodyType, CardType, FormGroup, FormRow, InputBottemBorderOnly2, LangText, SelectBottemBorderOnly2, Switch } from 'bambooo';
import { ContactAddressType } from 'kavula_schemas';
/**
 * Address widget
 */
export class AddressWidget extends Card {
    /**
     * Constructor
     * @param {AddressListWidget} element
     * @param {boolean} editable
     */
    constructor(element, editable = false) {
        super(element.getElement(), CardBodyType.none, CardType.success);
        /**
         * Id from address
         * @protected
         */
        this._id = '';
        /**
         * Id from contact
         * @protected
         */
        this._contactId = '';
        const groupStreet = new FormGroup(this, new LangText('Street'));
        this._inputStreet = new InputBottemBorderOnly2(groupStreet);
        const groupStreet2 = new FormGroup(this, new LangText('Street 2'));
        this._inputStreet2 = new InputBottemBorderOnly2(groupStreet2);
        const rowLR = new FormRow(this);
        const groupLocality = new FormGroup(rowLR.createCol(6), new LangText('Locality'));
        this._inputLocality = new InputBottemBorderOnly2(groupLocality);
        const groupRegion = new FormGroup(rowLR.createCol(6), new LangText('Region'));
        this._inputRegion = new InputBottemBorderOnly2(groupRegion);
        const rowPC = new FormRow(this);
        const groupPostalCode = new FormGroup(rowPC.createCol(6), new LangText('Postalcode'));
        this._inputPostalCode = new InputBottemBorderOnly2(groupPostalCode);
        const groupCountryName = new FormGroup(rowPC.createCol(6), new LangText('Countryname'));
        this._inputCountryName = new InputBottemBorderOnly2(groupCountryName);
        const rowMT = new FormRow(this);
        const groupMain = new FormGroup(rowMT.createCol(6), new LangText('Is main'));
        this._switchContactMain = new Switch(groupMain, this._uniqId());
        const groupType = new FormGroup(rowMT.createCol(6), new LangText('Type'));
        this._selectType = new SelectBottemBorderOnly2(groupType);
        this._selectType.addValue({
            key: `${ContactAddressType.none}`,
            value: 'None',
        });
        this._selectType.addValue({
            key: `${ContactAddressType.private}`,
            value: 'Private',
        });
        this._selectType.addValue({
            key: `${ContactAddressType.company}`,
            value: 'Company',
        });
        // buttons -----------------------------------------------------------------------------------------------------
        const removeUpstreamBtn = new ButtonDefault(this.getToolsElement(), '', 'fa-trash', ButtonClass.tool, ButtonDefaultType.none);
        removeUpstreamBtn.setOnClickFn(() => {
            element.removeAddress(this);
        });
        this.setReadOnly(!editable);
    }
    /**
     * Set readonly
     * @param readOnly
     */
    setReadOnly(readOnly) {
        this._inputStreet.setReadOnly(readOnly);
        this._inputStreet2.setReadOnly(readOnly);
        this._inputLocality.setReadOnly(readOnly);
        this._inputRegion.setReadOnly(readOnly);
        this._inputPostalCode.setReadOnly(readOnly);
        this._inputCountryName.setReadOnly(readOnly);
        this._switchContactMain.setInativ(readOnly);
    }
    /**
     * set address
     * @param address
     */
    setAddress(address) {
        this._id = address.id;
        this._contactId = address.contact_id;
        this._inputStreet.setValue(address.street);
        this._inputStreet2.setValue(address.street2);
        this._inputLocality.setValue(address.locality);
        this._inputRegion.setValue(address.region);
        this._inputPostalCode.setValue(address.postalcode);
        this._inputCountryName.setValue(address.countryname);
        this._switchContactMain.setEnable(address.contact_main);
        this._selectType.setSelectedValue(`${address.address_type}`);
    }
    /**
     * return address
     * @returns ContactAddress
     */
    getAddress() {
        return {
            id: this._id,
            contact_id: this._contactId,
            street: this._inputStreet.getValue(),
            street2: this._inputStreet2.getValue(),
            locality: this._inputLocality.getValue(),
            region: this._inputRegion.getValue(),
            postalcode: this._inputPostalCode.getValue(),
            countryname: this._inputCountryName.getValue(),
            contact_main: this._switchContactMain.isEnable(),
            address_type: this._selectType.getSelectedValue()
        };
    }
    remove() {
        this._element.remove();
    }
}
