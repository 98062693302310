export class HttpError extends Error {
    /**
     * constructor
     * @param message
     * @param status
     */
    constructor(message, status, url) {
        super(message);
        this._status = 0;
        this._url = '';
        this._status = status;
        this._url = url;
    }
    getStatus() {
        return this._status;
    }
    getUrl() {
        return this._url;
    }
}
