/**
 * Lang_EN
 */
export class Lang_EN {
    constructor() {
        this._content = {
            'title': 'Kavula',
            'login_title': '<b>Kavula</b>',
            'addressbook': 'Addressbook',
            'calendar': 'Calendar',
            'settings': 'Settings',
            'edit': 'Edit',
            'contact_title': 'Title',
            'person': 'Person',
            'company': 'Company',
            'firstname': 'Firstname',
            'lastname': 'Lastname',
            'birthday': 'Birthday',
            'private': 'Private',
            'companyname': 'Company Name',
            'address': 'Address',
            'phone': 'Phone',
            'add_contact': 'Add Contact',
            'add_calendar': 'Add Appointment',
            'calendar_title': 'Title',
            'details': 'Details',
            'date': 'Date',
            'time': 'Time',
            'description': 'Description',
            'participant': 'Participant',
            'repeat': 'Repeat',
            'alert': 'Alert',
            'location': 'Location',
            'duration': 'Duration',
            'category': 'Category',
            'priority': 'Priority',
            'My new Termin': 'My new Termin',
            'Save changes': 'Save changes',
            'Close': 'Close',
            'add_category': 'Add Category',
            'name': 'Name',
            'inquiry': 'Inquiry',
            'positions': 'Positions',
            'add_manufacturer': 'Add Manufacturer',
            'type': 'Type',
            'manufacturer': 'Manufacturer',
            'users': 'Users',
            'categories': 'Categories',
            'edit_manufacturer': 'Edit Manufacturer',
            'taximeter': 'Taximeter',
            'type approval': 'Type Approval',
            'action': 'Action',
            'add_taximeter': 'Add Taximeter',
            'edit_taximeter': 'Edit Taximeter',
            'please select a manufacturer': 'Please select a manufacturer',
            'vehicle types': 'Vehicle types',
            'sensortype': 'Sensor Type',
            'sensortap': 'Sensor Tap',
            'sensor_articlenumber': 'Sensor article number',
            'add_vehicletype': 'Add Vehicletype',
            'edit_vehicletype': 'Edit Vehicletype',
            'vehicle': 'Vehicle',
            'allowed_taximeter': 'Allowed Taximeter',
            'article': 'Article'
        };
    }
    /**
     * getLangCode
     */
    getLangCode() {
        return 'en';
    }
    /**
     * getLangTitle
     */
    getLangTitle() {
        return 'English';
    }
    /**
     * getCountryCode
     */
    getCountryCode() {
        return 'us';
    }
    /**
     * l
     * @param acontent
     */
    l(acontent) {
        if (this._content[acontent]) {
            return this._content[acontent];
        }
        return null;
    }
    /**
     * getClassName
     */
    getClassName() {
        return 'Lang_EN';
    }
}
