import { BlockButton, BlockButtonType, Card, CardBodyType, FormGroup, FormRow, Icon, InputBottemBorderOnly2, LangText } from 'bambooo';
import { SearchWidget } from '../../Widget/Search/SearchWidget';
/**
 * Inquiry address card
 */
export class InquiryAddressCard extends Card {
    /**
     * Constructor
     * @param {any} elementObject
     */
    constructor(elementObject) {
        super(elementObject, CardBodyType.none);
        this.getHeaderElement().hide();
        const groupSelectAddress = new FormGroup(this, new LangText('Addressbook'));
        this._selectAddressbook = new SearchWidget(groupSelectAddress);
        this._selectAddressbook.setOnTemplateSelection((entryData) => {
            const span = jQuery('<span />');
            // eslint-disable-next-line no-new
            new Icon(span, 'fa fa-solid fa-tags');
            span.append(` <span>${entryData.text}</span>`);
            return span;
        });
        this._selectAddressbook.setData([{ id: '1', text: 'Test' }]);
        const cardData = new Card(this, CardBodyType.profile);
        cardData.getHeaderElement().hide();
        const rowName = new FormRow(cardData);
        const groupFirstname = new FormGroup(rowName.createCol(6), new LangText('firstname'));
        this._firstname = new InputBottemBorderOnly2(groupFirstname);
        const groupLastname = new FormGroup(rowName.createCol(6), new LangText('lastname'));
        this._lastname = new InputBottemBorderOnly2(groupLastname);
        const groupCompany = new FormGroup(cardData, new LangText('company'));
        this._company = new InputBottemBorderOnly2(groupCompany);
        const rowAddres = new FormRow(cardData);
        const groupAddress1 = new FormGroup(rowAddres.createCol(6), new LangText('address 1'));
        this._address1 = new InputBottemBorderOnly2(groupAddress1);
        const groupAddress2 = new FormGroup(rowAddres.createCol(6), new LangText('address 2'));
        this._address2 = new InputBottemBorderOnly2(groupAddress2);
        const rowCS = new FormRow(cardData);
        const groupCity = new FormGroup(rowCS.createCol(6), new LangText('city'));
        this._city = new InputBottemBorderOnly2(groupCity);
        const groupState = new FormGroup(rowCS.createCol(6), new LangText('state'));
        this._state = new InputBottemBorderOnly2(groupState);
        const rowPC = new FormRow(cardData);
        const groupPostcode = new FormGroup(rowPC.createCol(6), new LangText('postcode'));
        this._postcode = new InputBottemBorderOnly2(groupPostcode);
        const groupCountry = new FormGroup(rowPC.createCol(6), new LangText('country'));
        this._country = new InputBottemBorderOnly2(groupCountry);
        const groupSelectAddressBtn = new FormGroup(cardData, new LangText('Action'));
        const addAddressbookBtn = new BlockButton(groupSelectAddressBtn.getElement(), BlockButtonType.BlockSmall);
        const addAddressbookBtnLang = new LangText('Add Addressbook');
        addAddressbookBtnLang.appendTo(addAddressbookBtn.getElement());
        this._selectAddressbook.setOnSelect(() => {
            groupSelectAddressBtn.hide();
        });
        this._selectAddressbook.setOnUnselect(() => {
            groupSelectAddressBtn.show();
        });
    }
    getAddressbookId() {
        return this._selectAddressbook.getValue();
    }
    setReadonly(readonly) {
        this._firstname.setReadOnly(readonly);
        this._lastname.setReadOnly(readonly);
        this._company.setReadOnly(readonly);
        this._address1.setReadOnly(readonly);
        this._address2.setReadOnly(readonly);
        this._city.setReadOnly(readonly);
        this._state.setReadOnly(readonly);
        this._postcode.setReadOnly(readonly);
        this._country.setReadOnly(readonly);
    }
    /**
     * Set address
     * @param {InquiryAddress} address
     */
    setAddress(address) {
        this._firstname.setValue(address.firstname);
        this._lastname.setValue(address.lastname);
        this._company.setValue(address.company);
        this._address1.setValue(address.address1);
        this._address2.setValue(address.address2);
        this._city.setValue(address.city);
        this._state.setValue(address.state);
        this._postcode.setValue(address.postalcode);
        this._country.setValue(address.country);
    }
}
