import { Card, CardBodyType, CardType } from 'bambooo';
import { ParticipantType } from 'kavula_schemas';
/**
 * Participant widget
 */
export class ParticipantWidget extends Card {
    /**
     * Constructor
     * @param {AddressListWidget} element
     * @param {boolean} editable
     */
    constructor(element, editable = false) {
        super(element.getElement(), CardBodyType.none, CardType.none);
        /**
         * Type of participant
         * @protected
         */
        this._type = ParticipantType.text;
        this.setReadOnly(!editable);
        this.getHeaderElement().hide();
    }
    /**
     * Set readonly
     * @param readOnly
     */
    setReadOnly(readOnly) {
        console.log(`Readonly ${readOnly}`);
    }
    /**
     * Set type
     * @param {ParticipantType} type
     */
    setType(type) {
        this._type = type;
    }
    remove() {
        this._element.remove();
    }
}
