import { ButtonClass, ButtonShowMore, DialogInfo, ModalDialogType, UtilRedirect } from 'bambooo';
import { Vts } from 'vts';
import { SchemaError } from '../Net/Error/SchemaError';
import { UnauthorizedError } from '../Net/Error/UnauthorizedError';
/**
 * Widget Error Dialog catch errors to a dialog
 */
export class ErrorDialog {
    /**
     * Call code and catch exception and create a Dialog for the user
     * @param {callFunc} fun
     */
    static async callTry(fun) {
        try {
            return await fun();
        }
        catch (e) {
            let msg = '';
            let details = '';
            let internFunc = null;
            if (Vts.isInstanceOf(e, SchemaError)) {
                msg = e.message;
                details = e.errorsToStr();
            }
            else if (Vts.isInstanceOf(e, UnauthorizedError)) {
                msg = e.message === '' ? 'The session is no longer valid. Please log in again.' : e.message;
                internFunc = () => {
                    UtilRedirect.toLogin();
                };
            }
            else {
                msg = 'Unknown error object!';
            }
            DialogInfo.info('errorDialog', ModalDialogType.large, 'Error', (modal) => {
                const bcard = modal.getBodyCardElement();
                bcard.empty();
                bcard.append(`<h3><i class="fas fa-exclamation-triangle text-danger"></i> ${msg}</h3>`);
                if (details !== '') {
                    const p = jQuery('<p></p>').appendTo(bcard);
                    const btnMore = new ButtonShowMore(p, 'Show more details', `<pre>${details}</pre>`);
                    btnMore.show();
                }
            }, (_, modal) => {
                if (Vts.isFunction(internFunc)) {
                    internFunc();
                }
                modal.hide();
            }, 'Ok', ButtonClass.danger);
            console.error(msg, e, details);
        }
        return null;
    }
}
