/**
 * InternalError
 */
export class InternalError extends Error {
    /**
     * constructor
     * @param message
     */
    constructor(message) {
        super(message);
    }
}
