import { BasePage } from './BasePage';
/**
 * Dashboard
 */
export class Dashboard extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'dashboard';
        this.setTitle('Dashboard');
    }
}
