import { BasePage } from './BasePage';
/**
 * Settings
 */
export class Settings extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'settings';
        this.setTitle('Settings');
    }
}
