import { ModalDialog, ModalDialogType, LangText, NavTab, Card, CardBodyType } from 'bambooo';
import { Lang } from '../../Lang/Lang';
import { InquiryAddressCard } from './InquiryAddressCard';
import { InquiryOverviewCard } from './InquiryOverviewCard';
import { InquiryPositionList } from './InquiryPositionList';
/**
 * Inquiry edit modal
 */
export class InquiryEditModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'inquirymodaldialog', ModalDialogType.xlarge);
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        this.setTitle(new LangText('inquiry'));
        this._navTab = new NavTab(this._body, 'inquirynavtab');
        // Overview ----------------------------------------------------------------------------------------------------
        const tabOverview = this._navTab.addTab(new LangText('overview'), 'inquiry_overview');
        tabOverview.tab.show();
        this._overview = new InquiryOverviewCard(tabOverview.body);
        // Billing -----------------------------------------------------------------------------------------------------
        const tabBilling = this._navTab.addTab(new LangText('billing'), 'inquiry_billing');
        tabBilling.tab.show();
        this._billingAddress = new InquiryAddressCard(tabBilling.body);
        // Shipping ----------------------------------------------------------------------------------------------------
        const tabShipping = this._navTab.addTab(new LangText('shipping'), 'inquiry_shipping');
        tabShipping.tab.show();
        this._shippingAddress = new InquiryAddressCard(tabShipping.body);
        // Position ----------------------------------------------------------------------------------------------------
        const tabPosition = this._navTab.addTab(new LangText('positions'), 'position_inquiry');
        const positionCard = new Card(tabPosition.body, CardBodyType.none);
        positionCard.getHeaderElement().hide();
        this._positions = new InquiryPositionList(positionCard.getElement());
        // buttons -----------------------------------------------------------------------------------------------------
        jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        const btnProcess = jQuery(`<button type="button" class="btn btn-success">${Lang.i().l('Process start')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
        btnProcess.on('click', async () => {
            alert('test');
            console.log(this._billingAddress.getAddressbookId());
        });
    }
    /**
     * Set overview data
     * @param {InquiryOverviewCardData} data
     */
    setOverviewData(data) {
        this._overview.setData(data);
    }
    setBilling(address) {
        this._billingAddress.setAddress(address);
    }
    setShipping(address) {
        this._shippingAddress.setAddress(address);
    }
}
