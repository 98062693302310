import { SchemaCategoryListResponse, SchemaDefaultReturn } from 'kavula_schemas';
import { NetFetch } from '../Net/NetFetch';
/**
 * Categories api
 */
export class CategoriesApi {
    /**
     * Return the list with categories
     * @returns {CategoryListResponse}
     */
    static async getList() {
        return await NetFetch.postData('/json/v1/category/list', {}, SchemaCategoryListResponse);
    }
    /**
     * Save a category
     * @param {CategoryEntry} category
     */
    static async save(category) {
        const request = {
            category
        };
        await NetFetch.postData('/json/v1/category/save', request, SchemaDefaultReturn);
        return true;
    }
}
