import { FormGroup, InputBottemBorderOnly2, LangText, ModalDialog, ModalDialogType, SelectBottemBorderOnly2 } from 'bambooo';
import { ManufacturerType } from 'kavula_schemas';
import { Lang } from '../../../Lang/Lang';
/**
 * Taximeter Edit Modal
 */
export class TaximeterEditModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'taximetermodaldialog', ModalDialogType.large);
        /**
         * Id
         * @protected
         */
        this._id = 0;
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        const groupName = new FormGroup(this._body, new LangText('name'));
        this._inputName = new InputBottemBorderOnly2(groupName);
        const groupManufacturer = new FormGroup(this._body, new LangText('manufacturer'));
        this._selectManufacturer = new SelectBottemBorderOnly2(groupManufacturer);
        const groupTypeApproval = new FormGroup(this._body, new LangText('type approval'));
        this._inputTypeApproval = new InputBottemBorderOnly2(groupTypeApproval);
        // buttons -----------------------------------------------------------------------------------------------------
        jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
    }
    /**
     * Set an ID
     * @param {number} id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return an ID
     * @returns {number}
     */
    getId() {
        return this._id;
    }
    /**
     * Set Name
     * @param {string} name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * Return a Name
     * @returns {string}
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * Set the manufacturer list
     * @param ManufacturerData[] manufacturers
     */
    setManufacturerList(manufacturers) {
        this._selectManufacturer.clearValues();
        this._selectManufacturer.addValue({
            key: '0',
            value: Lang.i().l('please select a manufacturer'),
        });
        for (const manufacturer of manufacturers) {
            if (`${manufacturer.type}` === ManufacturerType.taximeter) {
                this._selectManufacturer.addValue({
                    key: `${manufacturer.id}`,
                    value: `#${manufacturer.id} ${manufacturer.name}`
                });
            }
        }
    }
    /**
     * Set the Manufacturer
     * @param {number} manufacturerId
     */
    setManufacturer(manufacturerId) {
        this._selectManufacturer.setSelectedValue(`${manufacturerId}`);
    }
    /**
     * Return Manufacturer
     * @param {number}
     */
    getManufacturer() {
        return parseInt(this._selectManufacturer.getSelectedValue(), 10) || 0;
    }
    /**
     * Set the type approval
     * @param {string} typeApproval
     */
    setTypeApproval(typeApproval) {
        this._inputTypeApproval.setValue(typeApproval);
    }
    /**
     * Return the type approval
     * @returns {string}
     */
    getTypeApproval() {
        return this._inputTypeApproval.getValue();
    }
    /**
     * resetValues
     */
    resetValues() {
        this._id = 0;
        this._inputName.setValue('');
        this._selectManufacturer.setSelectedValue('');
        this._inputTypeApproval.setValue('');
    }
    /**
     * set on save event
     * @param {TaximeterEditModalOnClickFn} onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
