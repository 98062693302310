import { ButtonClass, ButtonMenu, ButtonType, Card, ColumnContent, ContentCol, ContentColSize, ContentRow, DialogInfo, IconFa, InfoBox, InfoBoxBg, InfoBoxMb, LangText, LeftNavbarLink, ModalDialogType, Table, Td, Th, Tr } from 'bambooo';
import { SettingApi } from '../../Api/SettingApi';
import { Lang } from '../../Lang/Lang';
import { ErrorDialog } from '../../Widget/ErrorDialog';
import { BasePage } from '../BasePage';
import { TaximeterEditModal } from './Taximeter/TaximeterEditModal';
/**
 * Taximeter Page
 */
export class Taximeter extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * name
         * @protected
         */
        this._name = 'settings_taximeter';
        this._taximeterDialog = new TaximeterEditModal(this._wrapper.getContentWrapper().getContent());
        this.setTitle(`${Lang.i().l('settings')} - ${Lang.i().l('taximeter')}`);
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), new LangText('add_taximeter'), async () => {
            this._taximeterDialog.resetValues();
            this._taximeterDialog.setTitle(Lang.i().l('add_taximeter'));
            const manufacturers = await ErrorDialog.callTry(() => {
                return SettingApi.getManufacturerList();
            });
            if (!manufacturers || manufacturers.length === 0) {
                DialogInfo.info('errorDialog', ModalDialogType.large, 'Error', 'Please add frist Manufacturers!', undefined, 'Ok', ButtonClass.danger);
                return false;
            }
            this._taximeterDialog.setManufacturerList(manufacturers);
            this._taximeterDialog.show();
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
        // -------------------------------------------------------------------------------------------------------------
        this._taximeterDialog.setOnSave(async () => {
            try {
                const data = {
                    id: this._taximeterDialog.getId(),
                    name: this._taximeterDialog.getName(),
                    manufacturer_id: this._taximeterDialog.getManufacturer(),
                    type_approval: this._taximeterDialog.getTypeApproval()
                };
                if (await SettingApi.saveTaximeter(data)) {
                    this._taximeterDialog.hide();
                    if (this._onLoadTable) {
                        this._onLoadTable();
                    }
                    this._toast.fire({
                        icon: 'success',
                        title: 'Taximeter save success.'
                    });
                }
            }
            catch (message) {
                this._toast.fire({
                    icon: 'error',
                    title: message
                });
            }
        });
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            const content = this._wrapper.getContentWrapper().getContent();
            const row1 = new ContentRow(content);
            const card = new Card(new ContentCol(row1, ContentColSize.col12));
            card.setTitle(new LangText('taximeter'));
            card.showLoading();
            const table = new Table(card.getElement());
            const trhead = new Tr(table.getThead());
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('ID')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('name')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('manufacturer')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('type approval')
            ]));
            // eslint-disable-next-line no-new
            new Th(trhead, new ColumnContent([
                new LangText('action')
            ]));
            const manufacturers = await ErrorDialog.callTry(() => {
                return SettingApi.getManufacturerList();
            });
            if (!manufacturers || manufacturers.length === 0) {
                const ib = new InfoBox(new ContentCol(new ContentRow(card.getElement()), ContentColSize.col12), InfoBoxBg.none, InfoBoxMb.none);
                ib.setIcon(IconFa.info, InfoBoxBg.info);
                ib.getTextElement().append(Lang.i().l('Please insert manufacturer first!'));
            }
            else {
                const manufacturerMap = new Map();
                for (const emanufacturer of manufacturers) {
                    manufacturerMap.set(emanufacturer.id, emanufacturer);
                }
                // -----------------------------------------------------------------------------------------------------
                const taximeters = await ErrorDialog.callTry(() => {
                    return SettingApi.getTaximeterList();
                });
                if (taximeters) {
                    for (const taximeter of taximeters) {
                        const trbody = new Tr(table.getTbody());
                        // eslint-disable-next-line no-new
                        new Td(trbody, `#${taximeter.id}`);
                        // eslint-disable-next-line no-new
                        new Td(trbody, `${taximeter.name}`);
                        const manuTd = new Td(trbody, '');
                        const manufacturer = manufacturerMap.get(taximeter.manufacturer_id);
                        if (manufacturer) {
                            manuTd.append(`#${manufacturer.id} - ${manufacturer.name}`);
                        }
                        else {
                            manuTd.append(Lang.i().l('Unknown'));
                        }
                        // eslint-disable-next-line no-new
                        new Td(trbody, `${taximeter.type_approval}`);
                        // action
                        const actionTd = new Td(trbody, '');
                        const btnMenu = new ButtonMenu(actionTd, IconFa.bars, true, ButtonType.borderless);
                        btnMenu.addMenuItem('Edit', () => {
                            this._taximeterDialog.resetValues();
                            this._taximeterDialog.setTitle(Lang.i().l('edit_taximeter'));
                            this._taximeterDialog.setId(taximeter.id);
                            this._taximeterDialog.setName(taximeter.name);
                            this._taximeterDialog.setManufacturerList(manufacturers);
                            this._taximeterDialog.setManufacturer(taximeter.manufacturer_id);
                            this._taximeterDialog.setTypeApproval(taximeter.type_approval);
                            this._taximeterDialog.show();
                        }, IconFa.edit);
                    }
                }
            }
            card.hideLoading();
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
