import { FormGroup, InputBottemBorderOnly2, InputType, LangText, ModalDialog, ModalDialogType, NavTab, SelectBottemBorderOnly2 } from 'bambooo';
import { ManufacturerType } from 'kavula_schemas';
import { Lang } from '../../../Lang/Lang';
import { VehicleTypesTaximeterList } from './VehicleTypesTaximeterList';
/**
 * VehicleTypes Edit Modal
 */
export class VehicleTypesModal extends ModalDialog {
    /**
     * constructor
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'vehicletypesmodaldialog', ModalDialogType.large);
        /**
         * Id
         * @protected
         */
        this._id = 0;
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        this._navTab = new NavTab(this._body, 'vehicletypesnavtab');
        // vehicle -----------------------------------------------------------------------------------------------------
        const tabVehicle = this._navTab.addTab(new LangText('vehicle'), 'vehicletypes_vehicle');
        const groupName = new FormGroup(tabVehicle.body, new LangText('name'));
        this._inputName = new InputBottemBorderOnly2(groupName);
        const groupManufacturer = new FormGroup(tabVehicle.body, new LangText('manufacturer'));
        this._selectManufacturer = new SelectBottemBorderOnly2(groupManufacturer);
        const groupSensorType = new FormGroup(tabVehicle.body, new LangText('sensortype'));
        this._inputSensorType = new InputBottemBorderOnly2(groupSensorType, undefined, InputType.number);
        const groupSensorTap = new FormGroup(tabVehicle.body, new LangText('sensortap'));
        this._inputSensorTap = new InputBottemBorderOnly2(groupSensorTap);
        const groupSensorArticleNumber = new FormGroup(tabVehicle.body, new LangText('sensor_articlenumber'));
        this._inputSensorArticleNumber = new InputBottemBorderOnly2(groupSensorArticleNumber);
        // taximeter ---------------------------------------------------------------------------------------------------
        const tabTax = this._navTab.addTab(new LangText('allowed_taximeter'), 'vehicletypes_taximeter');
        this._taximeterList = new VehicleTypesTaximeterList(tabTax.body, true);
        // buttons -----------------------------------------------------------------------------------------------------
        jQuery(`<button type="button" class="btn btn-default" data-dismiss="modal">${Lang.i().l('Close')}</button>`).appendTo(this._footer);
        const btnSave = jQuery(`<button type="button" class="btn btn-primary">${Lang.i().l('Save changes')}</button>`).appendTo(this._footer);
        btnSave.on('click', async () => {
            if (this._onSaveClick !== null) {
                this.showLoading();
                await this._onSaveClick();
                this.hideLoading();
            }
        });
    }
    /**
     * Set an ID
     * @param {number} id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return an ID
     * @returns {number}
     */
    getId() {
        return this._id;
    }
    /**
     * Set Name
     * @param {string} name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * Return a Name
     * @returns {string}
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * Set the manufacturer list
     * @param ManufacturerData[] manufacturers
     */
    setManufacturerList(manufacturers) {
        this._selectManufacturer.clearValues();
        this._selectManufacturer.addValue({
            key: '0',
            value: Lang.i().l('please select a manufacturer'),
        });
        for (const manufacturer of manufacturers) {
            if (`${manufacturer.type}` === ManufacturerType.automobile) {
                this._selectManufacturer.addValue({
                    key: `${manufacturer.id}`,
                    value: `#${manufacturer.id} ${manufacturer.name}`
                });
            }
        }
    }
    /**
     * Set the Manufacturer
     * @param {number} manufacturerId
     */
    setManufacturer(manufacturerId) {
        this._selectManufacturer.setSelectedValue(`${manufacturerId}`);
    }
    /**
     * Return Manufacturer
     * @param {number}
     */
    getManufacturer() {
        return parseInt(this._selectManufacturer.getSelectedValue(), 10) || 0;
    }
    /**
     * Set the sensor type
     * @param {number} type
     */
    setSensorType(type) {
        this._inputSensorType.setValue(`${type}`);
    }
    /**
     * Return the sensor type
     * @returns {number}
     */
    getSensorType() {
        return parseInt(this._inputSensorType.getValue(), 10) || 0;
    }
    /**
     * Set the sensor typ
     * @param {string} tap
     */
    setSensorTap(tap) {
        this._inputSensorTap.setValue(tap);
    }
    /**
     * Return the sensor tap
     * @returns {string}
     */
    getSensorTap() {
        return this._inputSensorTap.getValue();
    }
    /**
     * Set the sensor article number
     * @param {string} number
     */
    setSensorArticleNumber(number) {
        this._inputSensorArticleNumber.setValue(number);
    }
    /**
     * Return the sensor article number
     */
    getSensorArticleNumber() {
        return this._inputSensorArticleNumber.getValue();
    }
    /**
     * Set the vehicle types taximeter
     * @param {VehicleTaximeter[]} list
     */
    setVehicleTypesTaximeter(list) {
        this._taximeterList.setList(list);
    }
    /**
     * Return the vehicle types taximeter
     * @param {VehicleTaximeter[]}
     */
    getVehicleTypesTaximeter() {
        return this._taximeterList.getList();
    }
    /**
     * Set the taximeter list
     * @param {Taximeter[]} taximeters
     */
    setTaximeterList(taximeters) {
        this._taximeterList.setTaximeterList(taximeters);
    }
    /**
     * resetValues
     */
    resetValues() {
        this._id = 0;
        this._inputName.setValue('');
        this._selectManufacturer.setSelectedValue('');
        this._inputSensorType.setValue('0');
        this._inputSensorTap.setValue('');
        this._inputSensorArticleNumber.setValue('');
        this._navTab.setTabSelect(0);
        this._taximeterList.removeAll();
    }
    /**
     * set on save event
     * @param {VehicleTypesModalOnClickFn} onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
